import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Icon, IconButton, ListTable } from '@atom/mui';
import { AssignedRole } from '@atom/types/role';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

const { TableRow, TableCell } = ListTable;

const styles = {
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIconButton: {
    padding: '0',
    marginRight: '1rem',
  },
};

interface Props {
  role: AssignedRole;
  onDelete: (id: string) => void;
}

const AssignedRolesTable = ({ role, onDelete }: Props) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const handleDelete = (id: string) => {
    onDelete(id);
    setOpenDelete(false);
  };

  const inheritedFrom = useMemo(() => {
    if (!role.inheritedFrom) {
      return '';
    }

    return role.inheritedFrom.map(({ name }) => name).join(', ');
  }, [role]);

  return (
    <>
      <TableRow key={`${role.id}-${inheritedFrom}`}>
        <TableCell>
          <Link to={`/team/role/${role.id}`} style={styles.link}>
            {inheritedFrom && (
              <IconButton
                tooltip={inheritedFrom}
                style={styles.tooltipIconButton}
              >
                <Icon>people</Icon>
              </IconButton>
            )}
            {role.name}
          </Link>
        </TableCell>
        <TableCell>{role.description}</TableCell>
        {/* @ts-ignore */}
        <TableCell>{getUserFullName(role.assignedBy)}</TableCell>
        <TableCell>{setDisplayDate(role.assignedDate)}</TableCell>
        <TableCell width="48px">
          {hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
          !inheritedFrom &&
          !role.isDefault ? (
            <IconButton onClick={() => setOpenDelete(true)}>
              <Icon>delete</Icon>
            </IconButton>
          ) : (
            <div style={{ height: '48px' }} />
          )}
        </TableCell>
      </TableRow>
      <DeleteModal
        title="Remove Role"
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onConfirm={() => handleDelete(role.id)}
        confirmText="Remove"
        content="Are you sure you want to remove this role from this user/group?"
      />
    </>
  );
};

export default AssignedRolesTable;
