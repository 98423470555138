import React, { useContext } from 'react';
import * as R from 'ramda';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import { Checkbox, Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnitTree } from '@atom/types/budget';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetingApprovalSelectionContext from './BudgetingApprovalSelectionContext';

const TITLE_WIDTH_REMS = 25;
const TITLE_TOOLTIP_REMS = 21;

const styles = {
  arrowIcon: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0',
  },
  folderIcon: {
    fontSize: '1.75rem',
    marginRight: '0.25rem',
    padding: '0.25rem 0',
  },
  blankIcon: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0.75rem',
  },
  unitNameContainer: {
    display: 'flex',
    flexFlow: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  unitIconContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    maxWidth: `${TITLE_WIDTH_REMS}rem`,
  },
};

export interface Props {
  tree: BudgetUnitTree;
  indentation?: number;
}

const BudgetingApprovalSelectionTree = ({ tree, indentation = 0 }: Props) => {
  const { collapsed, setCollapsed, selectedUnitIds, handleChange } = useContext(
    BudgetingApprovalSelectionContext,
  );
  // TODO: Add UI option to select all children
  //       https://atomai.atlassian.net/browse/AM-15648
  const selected = selectedUnitIds.has(tree.id);

  const leftIcons = (
    <>
      {!R.isEmpty(tree.children) ? (
        <IconButton
          style={styles.arrowIcon}
          onClick={() => setCollapsed(toggleFromSet(collapsed, tree.id))}
        >
          <Icon color={colors.neutral.dim}>
            {collapsed.has(tree.id) ? 'arrow_right' : 'arrow_drop_down'}
          </Icon>
        </IconButton>
      ) : (
        <div style={styles.blankIcon} />
      )}
      <Checkbox checked={selected} onChange={() => handleChange(tree)} />
      <Icon
        onClick={() => handleChange(tree)}
        style={styles.folderIcon}
        color={
          isNilOrEmpty(tree.children)
            ? colors.utility.activeBlue
            : colors.neutral.gray
        }
      >
        folder
      </Icon>
    </>
  );

  const containerStyle = {
    paddingLeft: `${indentation}rem`,
    maxWidth: `${TITLE_WIDTH_REMS}rem`,
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={styles.unitIconContainer}>
          {leftIcons}
          <div
            onClick={() => handleChange(tree)}
            style={styles.unitNameContainer}
          >
            <TextOverflowTooltip
              width={`${TITLE_TOOLTIP_REMS - indentation}rem`}
              text={tree?.name}
            />
          </div>
        </div>
      </div>
      {!collapsed.has(tree?.id) &&
        tree.children.map((unit: BudgetUnitTree) => {
          return (
            <BudgetingApprovalSelectionTree
              key={unit.id}
              tree={unit}
              indentation={indentation + 1}
            />
          );
        })}
    </>
  );
};

export default BudgetingApprovalSelectionTree;
