import React, { useContext } from 'react';
import * as R from 'ramda';

import CreateAssetModalContext, {
  ModalStep,
} from '@atom/components/common/createAssetModal/CreateAssetModalContext';
import { Stepper } from '@atom/mui';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './createAssetModal.css';

const { StepLabel, Step } = Stepper;

const StepperSidebar = () => {
  const {
    activeStep,
    STEPS_ARRAY,
    schema,
    category,
    rate,
    unit,
    name,
    errorAttributeIds,
    address,
  } = useContext(CreateAssetModalContext);

  const activeStepIndex = R.findIndex(R.propEq('value', activeStep))(
    STEPS_ARRAY,
  );

  const getStepSubtext = (step: ModalStep) => {
    switch (step) {
      case ModalStep.TYPE: {
        return schema?.name || '';
      }
      case ModalStep.FOLDER: {
        return category?.name || '';
      }
      case ModalStep.LOCATION: {
        return address || '';
      }
      case ModalStep.COST: {
        return (!isNilOrEmpty(rate) && unit) ||
          activeStep === ModalStep.COST ? (
          <span styleName="cost-subtext">{`${numberToLocaleString(
            rate,
          )} / ${unit}`}</span>
        ) : (
          ''
        );
      }
      case ModalStep.NAME: {
        return name || '';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div styleName="stepper-container">
      <Stepper activeStep={activeStepIndex} orientation="vertical">
        {STEPS_ARRAY.map(step => {
          const subtext = getStepSubtext(step.value);
          const error =
            step.value === ModalStep.ATTRIBUTES && errorAttributeIds.size > 0;

          return (
            <Step key={step.title}>
              <StepLabel error={error}>
                <>
                  <div>{step.title}</div>
                  {subtext && <div styleName="step-subtext">{subtext}</div>}
                </>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperSidebar;
