import React, { useContext } from 'react';

import { GrantType } from '@atom/types/policy';

import BudgetingApprovalTemplateSelection from './Budgeting/BudgetingApprovalTemplateSelection';
import BudgetingSelection from './Budgeting/BudgetingSelection';
import BudgetingTemplateSelection from './Budgeting/BudgetingTemplateSelection';
import BudgetingUnitTemplateSelection from './Budgeting/BudgetingUnitTemplateSelection';
import PolicyGenericGrantsSelection from './PolicyGenericGrantsSelection';
import PolicyModalContext from './PolicyModalContext';
import PolicySchemaSelection from './PolicySchemaSelection';
import PolicyUserSelection from './PolicyUserSelection';

import './policyModals.css';

const PolicyGrantsSelection = () => {
  const {
    state: { grantType },
  } = useContext(PolicyModalContext);

  if (!grantType) {
    return null;
  }

  switch (grantType) {
    case GrantType.BUDGETING_BUDGET: {
      return (
        <div styleName="input-container">
          <BudgetingSelection />
        </div>
      );
    }
    case GrantType.BUDGETING_TEMPLATE: {
      return (
        <div styleName="input-container">
          <BudgetingTemplateSelection />
        </div>
      );
    }
    case GrantType.BUDGETING_UNIT: {
      return (
        <div styleName="input-container">
          <BudgetingUnitTemplateSelection />
        </div>
      );
    }
    case GrantType.BUDGETING_APPROVAL: {
      return (
        <div styleName="input-container">
          <BudgetingApprovalTemplateSelection />
        </div>
      );
    }
    case GrantType.INVENTORY_TYPE: {
      return (
        <div styleName="input-container">
          <PolicySchemaSelection />
        </div>
      );
    }
    case GrantType.USER: {
      return (
        <div styleName="input-container">
          <PolicyUserSelection />
        </div>
      );
    }
    case GrantType.WORK_LIST: {
      return <></>;
    }
    default:
      return <PolicyGenericGrantsSelection />;
  }
};

export default PolicyGrantsSelection;
