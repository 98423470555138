import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import TextField from '@atom/components/common/TextField';
import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { UserActions } from '@atom/types/actions';
import { EventType } from '@atom/types/event';
import accountUtilities from '@atom/utilities/accountUtilities';

import AuthLogo from './AuthLogo';

import './login.css';

const styles = {
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: '1.875rem 1.875rem 1rem 1.875rem',
    },
    contentStyle: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingTop: '2rem',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingTop: '1.5rem',
      paddingBottom: '1.125rem',
    },
  },
  logo: {
    width: '100px',
  },
  backButton: {
    color: colors.neutral.gray,
    padding: 0,
  },
  emailIcon: {
    color: colors.neutral.gray,
    marginTop: '0.125rem',
    paddingLeft: '1rem',
  },
  input: {
    border: 'none',
    height: '38px',
    lineHeight: '38px',
    fontSize: '14px',
    outline: 'none',
  },
};

const initialState = {
  open: false,
  errors: {
    email: '',
  },
  email: '',
};

interface ErrorsState {
  email: string;
}

interface ReduxDispatchProps {
  userActions: UserActions;
}

type Props = ReduxDispatchProps;

interface State {
  email: string;
  errors: ErrorsState;
  open: boolean;
}

class ResetPasswordModal extends React.Component<Props, State> {
  state = initialState;

  closeModal = () => {
    this.setState(initialState);
  };

  openModal = () => {
    this.setState({
      ...initialState,
      open: true,
    });
  };

  onChange = (event: EventType) => {
    const { name: property, value } = event.target;
    // @ts-ignore
    this.setState({ [property]: value });
  };

  isValid = (): boolean => {
    const { email } = this.state;
    if (!accountUtilities.isValidEmail(email)) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: 'Please enter a valid email address',
        },
      });
      return false;
    }

    return true;
  };

  submit = () => {
    const { userActions } = this.props;
    const { email } = this.state;

    if (this.isValid()) {
      userActions.requestUserPasswordReset({ email });
      this.closeModal();
    }
  };

  render() {
    const { open, email, errors } = this.state;
    const isSubmitDisabled = R.isNil(email) || R.isEmpty(email);

    return (
      <React.Fragment>
        <div
          onClick={this.openModal}
          styleName="password-reset-label-container"
        >
          Forgot password?
        </div>
        <Modal
          title=""
          open={open}
          style={styles.dialog.bodyStyle}
          width="sx"
          disableHeader
          disableFooter
          contentStyle={styles.dialog.contentStyle}
        >
          <div styleName="password-reset-modal-container">
            <div styleName="password-reset-logo-icon-container">
              <div styleName="password-reset-back-icon">
                <IconButton
                  onClick={this.closeModal}
                  iconStyle={styles.backButton}
                  tooltip="Back"
                >
                  <Icon>arrow_back</Icon>
                </IconButton>
              </div>
              <div styleName="password-reset-logo">
                <AuthLogo style={styles.logo} />
              </div>
            </div>
            <div styleName="password-reset-heading">Reset your password</div>
            <div styleName="password-reset-text">
              Please enter your email address. We will send you an email to
              reset your password.
            </div>
            {errors.email && <div styleName="error-text">{errors.email}</div>}
            <div styleName="password-reset-email-container">
              <Icon className="material-icons" style={styles.emailIcon}>
                email
              </Icon>
              <div styleName="password-reset-email-input">
                <TextField
                  name="email"
                  value={email}
                  placeholder="yours@example.com"
                  onChange={this.onChange}
                  underlineShow={false}
                />
              </div>
            </div>
          </div>
          <div style={styles.dialog.actionsContainerStyle}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={isSubmitDisabled}
              onClick={this.submit}
            >
              Send email
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(ResetPasswordModal);
