import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import GooglePlacesAutoComplete from '@atom/components/common/GooglePlacesAutoComplete';
import { Icon, IconButton } from '@atom/mui';
import { getLatLng } from '@atom/utilities/locationUtilities';
import {
  getGeoJSONLocation,
  getGeoJSONLocationFromCoordinateString,
} from '@atom/utilities/mapUtilities';

import CreateAssetModalContext from '../CreateAssetModalContext';

import CoordinatesInput from './CoordinatesInput';

import './pointLocationTab.css';

enum InputMode {
  ADDRESS = 'ADDRESS',
  COORDINATES = 'COORDINATES',
}

const LocationInputs = () => {
  const anchor = useRef();

  const {
    location,
    setLocation,
    address,
    setAddress,
    setLocationFromSearch,
  } = useContext(CreateAssetModalContext);

  const [inputMode, setInputMode] = useState<InputMode>(InputMode.ADDRESS);
  const [updateKey, setUpdateKey] = useState<number>(0);
  const [coordinatesInput, setCoordinatesInput] = useState<string>();
  const [invalidCoordinates, setInvalidCoordinates] = useState<boolean>(false);

  useEffect(() => {
    if (location) {
      const locationData = getLatLng(location?.coordinates);
      setCoordinatesInput(
        locationData ? `${locationData?.lat}, ${locationData?.lng}` : '',
      );
    }
  }, [location]);

  const onLocationChange = (
    newLocation: { lat: number; lng: number },
    searchTerm?: string,
  ) => {
    setAddress(searchTerm);
    setLocation(getGeoJSONLocation(newLocation));
    setLocationFromSearch(true);
    setInvalidCoordinates(false);
  };

  const handleClear = () => {
    setUpdateKey(prevKey => prevKey + 1);
    setLocation(null);
    setAddress(null);
    setCoordinatesInput(null);
    setInvalidCoordinates(false);
    setLocationFromSearch(false);
  };

  const handleCoordinatesChange = newCoordinatesInput => {
    setCoordinatesInput(newCoordinatesInput);

    const validCoordinateRegExp = new RegExp(
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    );
    const isCoordinatesValid = validCoordinateRegExp.test(newCoordinatesInput);

    if (isCoordinatesValid) {
      setInvalidCoordinates(false);
      setLocation(getGeoJSONLocationFromCoordinateString(newCoordinatesInput));
      setLocationFromSearch(true);
    } else {
      setInvalidCoordinates(true);
      setLocation(null);
      setAddress(null);
    }
  };

  const content = useMemo(() => {
    const error = (!address && !location) || invalidCoordinates;

    const inputContainerStyle = error
      ? 'input-container error'
      : 'input-container';
    const inputSubtextStyle = error ? 'input-subtext error' : 'input-subtext';

    const addressModeSubtext = error
      ? 'Please provide a valid address.'
      : coordinatesInput;
    const coordinateModeSubtext = error
      ? 'Please provide valid coordinates (eg. 12.34567, -12,34567).'
      : address;

    const inputContent = {
      [InputMode.ADDRESS]: (
        <>
          <div ref={anchor} styleName={inputContainerStyle}>
            <GooglePlacesAutoComplete
              key={updateKey}
              hintText="Enter address"
              initialValue={address}
              onSelect={onLocationChange}
              anchor={anchor.current}
              popoverWidth="35rem"
              textFieldStyle={{ fontSize: 14 }}
            />
            <IconButton onClick={handleClear} size="small">
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div styleName={inputSubtextStyle}>{addressModeSubtext}</div>
        </>
      ),
      [InputMode.COORDINATES]: (
        <>
          <div styleName={inputContainerStyle}>
            <CoordinatesInput
              coordinatesInput={coordinatesInput}
              handleCoordinatesChange={handleCoordinatesChange}
            />
            <IconButton onClick={handleClear} size="small">
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div styleName={inputSubtextStyle}>{coordinateModeSubtext}</div>
        </>
      ),
    };

    return inputContent[inputMode];
  }, [
    updateKey,
    anchor,
    address,
    onLocationChange,
    handleClear,
    coordinatesInput,
    setCoordinatesInput,
    handleCoordinatesChange,
    inputMode,
  ]);

  const toggleInputMode = () => {
    setInputMode(
      inputMode === InputMode.ADDRESS
        ? InputMode.COORDINATES
        : InputMode.ADDRESS,
    );
  };

  const swapTooltip =
    inputMode === InputMode.ADDRESS
      ? 'Switch to Coordinates Mode'
      : 'Switch to Address Mode';

  return (
    <div styleName="inputs-container">
      <div styleName="input-row">{content}</div>
      <IconButton onClick={toggleInputMode} tooltip={swapTooltip}>
        <Icon>swap_vert</Icon>
      </IconButton>
    </div>
  );
};

export default LocationInputs;
