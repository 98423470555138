import React from 'react';
import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';

type CustomProps = {
  /**
   * optional styles to be applied to the root
   */
  style?: React.CSSProperties;
  /**
   * optional text for button tooltip
   */
  tooltip?: NonNullable<React.ReactNode>;
  /**
   * optional props to pass to Tooltip
   */
  TooltipProps?: Omit<MUITooltipProps, 'children' | 'title'>;
  /**
   * passthrough for component prop in MUI that is excluded from the
   * official MUI button props. Read more at:
   * https://material-ui.com/guides/typescript/#usage-of-component-prop
   */
  component?: any;
  /**
   * Default size padding was reduced by 8px which makes default IconButton
   * size of 40px with the upgrade to @mui/material. To get old default size,
   * of 48px, size is set to "large".
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * lightTooltip will change basic styles for tooltip to
   * be a white background card
   */
  lightTooltip?: boolean;
};

export type IconButtonProps = CustomProps & MUIIconButtonProps;

const useStyles = makeStyles({
  // @ts-ignore
  root: ({ style }: IconButtonProps) => ({
    ...style,
  }),
  tooltip: {
    backgroundColor: colors.neutral.white,
    color: colors.neutral.black,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    fontSize: 11,
    fontWeight: 'normal',
  },
});

export const IconButton = (props: IconButtonProps) => {
  const classes = useStyles(props);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style: _,
    size = 'large',
    tooltip = '',
    TooltipProps = {},
    lightTooltip,
    ...data
  } = props;

  const {
    classes: toolTipPropsClasses,
    ...remainingTooltipProps
  } = TooltipProps;

  const toolTipClasses = {
    ...(lightTooltip && { tooltip: classes.tooltip }),
    ...toolTipPropsClasses,
  };

  if (!tooltip) {
    return (
      <MUIIconButton
        {...data}
        size={size}
        classes={{ root: classes.root, ...props.classes }}
      />
    );
  }

  return (
    <MUITooltip
      title={tooltip}
      classes={toolTipClasses}
      {...remainingTooltipProps}
    >
      <span>
        <MUIIconButton
          {...data}
          size={size}
          classes={{ root: classes.root, ...props.classes }}
        />
      </span>
    </MUITooltip>
  );
};
