import React, { useContext } from 'react';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import { InventoryCategoryTree } from '@atom/types/inventory';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CreateAssetModalContext from './CreateAssetModalContext';

import './createAssetModal.css';

const FolderSelectionTab = () => {
  const {
    schema,
    category,
    setCategory,
    openCategories,
    setOpenCategories,
  } = useContext(CreateAssetModalContext);
  const handleCategorySelect = (selectedCategory: InventoryCategoryTree) => {
    // Only select if category is leaf node
    if (isNilOrEmpty(selectedCategory?.categories)) {
      setCategory(selectedCategory);
    }
  };

  return (
    <div styleName="tab-container">
      <div styleName="tab-title">Inventory Folder</div>
      <div styleName="title-subtext">
        Select a folder to organize this inventory in Atom.
      </div>
      <div styleName="list-container folder">
        <InventoryAssetTree
          includeAssets={false}
          search="categories"
          onCategoryClick={data => handleCategorySelect(data)}
          onCategoryExpand={open => setOpenCategories(open)}
          openCategories={openCategories}
          disabledCategoryIds={new Set(['inventory'])}
          schemaId={schema?.id}
          selectedCategoryId={category?.id}
          includeMaterials={schema?.isMaterial}
          includeLocationBased={!schema?.isMaterial}
          onlyPublishedCategories
          dataCyPrefix="globalCreateAssetModalTree"
          isAssetCreate
        />
      </div>
    </div>
  );
};

export default FolderSelectionTab;
