import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import {
  GET_BUDGET_UNIT_TEMPLATE_TREE,
  SEARCH_BUDGET_UNITS,
} from '@atom/graph/budget';
import { Icon, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BudgetUnitSearch,
  BudgetUnitSearchConnection,
  BudgetUnitSearchInput,
  BudgetUnitTree,
} from '@atom/types/budget';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';
import { flattenTreeChildren } from '@atom/utilities/treeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import PolicyModalContext from '../PolicyModalContext';

import BudgetingApprovalSearchResults from './BudgetingApprovalSearchResults';
import BudgetingApprovalSelectionContext from './BudgetingApprovalSelectionContext';
import BudgetingApprovalSelectionTree from './BudgetingApprovalSelectionTree';

const MIN_SEARCH_CHARS = 2;
const DEBOUNCE_TIME = 500;
const SEARCH_RESULTS_LIMIT = 25;

const styles = {
  searchInput: {
    width: '25rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.5rem 0.5rem 0 0.5rem',
    borderBottom: `1px solid ${colors.neutral.gray}`,
    color: colors.neutral.gray,
  },
  searchInputClear: {
    cursor: 'pointer',
  },
  progress: { marginLeft: '0.5rem' },
};

const BudgetingApprovalSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants, budgetUnitTemplateId } = state;

  const [collapsed, setCollapsed] = useState<Set<string>>(new Set([]));
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<BudgetUnitSearch[]>(null);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);

  const { data: treeData, loading } = useQuery<
    { budgetUnitTreeForTemplateId: BudgetUnitTree },
    { id: string }
  >(GET_BUDGET_UNIT_TEMPLATE_TREE, {
    variables: { id: budgetUnitTemplateId },
  });
  const tree: BudgetUnitTree = useMemo(
    () => R.pathOr(null, ['budgetUnitTreeForTemplateId'], treeData),
    [treeData],
  );
  const rootId: string = useMemo(() => tree?.id, [tree]);

  const [
    searchUnits,
    { data: searchData, loading: loadingSearch },
  ] = useLazyQuery<
    { budgetUnitSearch: BudgetUnitSearchConnection },
    { input: BudgetUnitSearchInput }
  >(SEARCH_BUDGET_UNITS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    const nextResults = R.pathOr(
      [],
      ['budgetUnitSearch', 'budgetUnits'],
      searchData,
    );
    const newTotal = searchData?.budgetUnitSearch?.totalCount || 0;
    const newResults =
      searchPage === 1
        ? nextResults
        : [...(searchResults || []), ...nextResults];
    setSearchResults(newResults);
    setSearchTotal(newTotal);
  }, [searchData]);

  const searchUnitsDebounced = useCallback(
    debounce((queryString: string) => {
      setSearchPage(1);
      searchUnits({
        variables: {
          input: {
            budgetId: '',
            query: queryString,
            page: searchPage,
            limit: SEARCH_RESULTS_LIMIT,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (query.length > 1) {
      searchUnitsDebounced(query);
    } else {
      setSearchResults(null);
    }
  }, [query]);

  const handleSearchPageScroll = (nextPage: number) => {
    setSearchPage(nextPage);
    searchUnits({
      variables: {
        input: {
          budgetId: '',
          query,
          page: nextPage,
          limit: SEARCH_RESULTS_LIMIT,
        },
      },
    });
  };

  const selectedUnitIds: Set<string> = useMemo(
    () =>
      grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL)
        ? new Set([rootId])
        : new Set(grants.map(({ id }) => id)),
    [grants],
  );

  const handleChange = (unit: BudgetUnitTree) => {
    // Toggle selected unit
    const isSelected = selectedUnitIds.has(unit.id);
    const updated = isSelected
      ? grants.filter(({ id }) => id !== unit.id)
      : [...grants, { id: unit.id, name: unit.name }];

    // TODO: Move to handler for select all descendants
    // const getAllDescendantIds = (unit: BudgetUnitTree): string[] => {
    //   return unit.children.reduce(
    //     (acc: string[], child): string[] => [
    //       ...acc,
    //       child.id,
    //       ...getAllDescendantIds(child),
    //     ],
    //     [],
    //   );
    // };
    // const descendantIds = new Set(getAllDescendantIds(unit));
    // const filtered = updated.filter(({ id }) => !descendantIds.has(id));

    return updateState({ grants: updated });
  };

  const allUnits: BudgetUnitTree[] = useMemo(
    () => (!isNilOrEmpty(tree) ? flattenTreeChildren(tree) : []),
    [tree],
  );

  return (
    <BudgetingApprovalSelectionContext.Provider
      value={{
        query,
        setQuery,
        searchResults,
        setSearchResults,
        collapsed,
        setCollapsed,
        selectedUnitIds,
        handleChange,
        allUnits,
      }}
    >
      {R.isNil(tree) || loading ? (
        <Progress />
      ) : (
        <div>
          <div style={styles.searchInput}>
            <Icon>search</Icon>
            <TextField
              placeholder="Search"
              value={query}
              onChange={event => setQuery(event.target.value)}
              disableUnderline
            />
            {query.length > 0 && (
              <Icon
                style={styles.searchInputClear}
                onClick={() => setQuery('')}
              >
                close
              </Icon>
            )}
          </div>
          {R.isNil(searchResults) || query.length < MIN_SEARCH_CHARS ? (
            <BudgetingApprovalSelectionTree tree={tree} />
          ) : (
            <BudgetingApprovalSearchResults
              loadingSearch={loadingSearch}
              searchResults={searchResults}
              handlePageScroll={handleSearchPageScroll}
              page={searchPage}
              total={searchTotal}
            />
          )}
        </div>
      )}
    </BudgetingApprovalSelectionContext.Provider>
  );
};

export default BudgetingApprovalSelection;
