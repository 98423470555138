import { createContext } from 'react';

import { Task } from '@atom/types/task';
import { TaskLocation } from '@atom/types/taskLocation';
import { WorkOrderDetailType } from '@atom/types/work';

export enum LocationModalReturnView {
  TASK_MAP = 'TASK_MAP',
  NONE = 'NONE',
}

export enum ProceedAction {
  ADD_LOCATION = 'ADD_LOCATION',
  ADD_ASSET = 'ADD_ASSET',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}

export interface Context {
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  locations: TaskLocation[];
  assets: any[];
  refetch: () => void;
  refetchLoading: boolean;
  dataCySuffix: string;
}

const LocationsAndAssetsContext = createContext<Context>({
  // @ts-ignore
  workOrderDetail: {},
  // @ts-ignore
  task: {},
  assets: [],
  locations: [],
  refetch: () => {},
  refetchLoading: false,
  dataCySuffix: '',
});

export default LocationsAndAssetsContext;
