import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import LocationsAndAssetsContext, {
  LocationModalReturnView,
} from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import { GET_TASK_LOCATIONS } from '@atom/graph/taskLocation';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Task } from '@atom/types/task';
import {
  TaskLocation,
  TaskLocationsConnection,
  TaskLocationsConnectionInput,
} from '@atom/types/taskLocation';
import { WorkOrderDetailType } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isTaskMultiAssetInvalid } from '@atom/utilities/workValidationUtilities';

import AssetSection from './assetSection/AssetSection';
import LocationSection from './locationSection/LocationSection';
import TaskAssetModal from './taskAssetModal/TaskAssetModal';
import TaskLocationModal from './taskLocationModal/TaskLocationModal';
import TaskMapModal from './taskMapModal/TaskMapModal';

import './locationsAndAssetsSection.css';

interface Props {
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  currentTaskId?: string;
  refetch: () => void;
  previewView?: boolean;
}

const LocationsAndAssetsSection = ({
  workOrderDetail,
  task,
  currentTaskId = '',
  refetch,
  previewView = false,
}: Props) => {
  const {
    workValidations,
    resolveMultiAssetError,
    resolveMultiLocationError,
  } = useWorkValidations();

  const [openTaskAsset, setOpenTaskAsset] = useState<boolean>(false);
  const [openTaskLocation, setOpenTaskLocation] = useState<boolean>(false);
  const [openTaskMap, setOpenTaskMap] = useState<boolean>(false);
  const [locations, setLocations] = useState<TaskLocation[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  // Denotes where TaskLocationModal action buttons should return to.
  const [returnModal, setReturnModal] = useState<LocationModalReturnView>(
    LocationModalReturnView.NONE,
  );
  // Location in edit mode from task map modal
  const [editingLocation, setEditingLocation] = useState<TaskLocation>();
  const [refetchLoading, setRefetchLoading] = useState<boolean>(false);
  const [viewAssetId, setViewAssetId] = useState<string>('');

  const dataCySuffix = previewView ? 'workPreview' : 'workDetail';

  const assets = useMemo(() => {
    return task?.assetIds?.reduce((acc, assetId) => {
      const asset = R.pathOr(null, ['multiAssets', assetId], workOrderDetail);
      return isNilOrEmpty(asset) ? acc : [...acc, asset];
    }, []);
  }, [task, workOrderDetail]);

  useEffect(() => {
    setReturnModal(LocationModalReturnView.NONE);
  }, []);

  const locationsEnabled = task?.locationsEnabled;
  const assetsEnabled = task?.multiAssetsEnabled;

  useEffect(() => {
    const missingRequiredAsset = R.pathOr(
      false,
      ['taskValidations', task?.id, 'missingRequiredAsset'],
      workValidations,
    );

    const currentAssetsValid = !isTaskMultiAssetInvalid(workOrderDetail, task);

    if (missingRequiredAsset && currentAssetsValid) {
      resolveMultiAssetError(task.id);
    }
  }, [workOrderDetail, task]);

  const [getTaskLocations] = useLazyQuery<
    { taskLocations: TaskLocationsConnection },
    { input: TaskLocationsConnectionInput }
  >(GET_TASK_LOCATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const newLocations = R.pathOr(
        [],
        ['taskLocations', 'taskLocations'],
        data,
      );

      const missingRequiredLocation = R.pathOr(
        false,
        ['taskValidations', task?.id, 'missingRequiredLocation'],
        workValidations,
      );

      // If there is more than one location and the current task has a missing location error, update it
      if (newLocations.length > 0 && missingRequiredLocation) {
        resolveMultiLocationError(task.id);
      }

      setLocations(newLocations);
    },
  });

  const handleGetTaskLocations = async () => {
    const ids = R.pathOr([], ['locations'], task).map(item => item.id);

    if (!R.isEmpty(ids)) {
      await getTaskLocations({ variables: { input: { ids } } });
    }
  };

  const reloadLocations = async () => {
    setRefetchLoading(true);

    await handleGetTaskLocations();
    await refetch();

    setRefetchLoading(false);
  };

  useEffect(() => {
    if (
      locationsEnabled &&
      !isNilOrEmpty(task?.locations) &&
      (previewView ? true : currentTaskId === task?.id)
    ) {
      handleGetTaskLocations();
    } else {
      setLocations([]);
    }
  }, [task, task?.id, task?.locations]);

  const handleAddLocationClickFromMap = () => {
    setOpenTaskMap(false);
    setReturnModal(LocationModalReturnView.TASK_MAP);
    setOpenTaskLocation(true);
  };

  const handleEditLocationClickFromMap = (location: TaskLocation) => {
    setEditingLocation(location);
    setOpenTaskMap(false);
    setEditMode(true);
    setReturnModal(LocationModalReturnView.TASK_MAP);
    setOpenTaskLocation(true);
  };

  const handleAddAssetClickFromMap = () => {
    setOpenTaskMap(false);
    setReturnModal(LocationModalReturnView.TASK_MAP);
    setOpenTaskAsset(true);
  };

  const handleTaskAssetClose = () => {
    setOpenTaskAsset(false);

    if (returnModal === LocationModalReturnView.TASK_MAP) {
      setOpenTaskMap(true);
      setReturnModal(LocationModalReturnView.NONE);
    }
  };

  const handleTaskLocationClose = () => {
    setOpenTaskLocation(false);
    setEditMode(false);

    if (returnModal === LocationModalReturnView.TASK_MAP) {
      setOpenTaskMap(true);
      setReturnModal(LocationModalReturnView.NONE);
      setEditingLocation(null);
    }
  };

  const handleAssetTileClick = (assetId: string) => {
    setViewAssetId(assetId);
    setOpenTaskMap(true);
  };

  const handleTaskMapClose = () => {
    setViewAssetId('');
    setOpenTaskMap(false);
  };

  return (
    <LocationsAndAssetsContext.Provider
      value={{
        workOrderDetail,
        task,
        locations,
        assets,
        refetch: reloadLocations,
        refetchLoading,
        viewAssetId,
        dataCySuffix,
      }}
    >
      {locationsEnabled && (
        <LocationSection
          setOpenTaskLocation={setOpenTaskLocation}
          setOpenTaskMap={setOpenTaskMap}
          previewView={previewView}
        />
      )}
      {assetsEnabled && (
        <AssetSection
          setOpenTaskAsset={setOpenTaskAsset}
          setOpenTaskMap={setOpenTaskMap}
          previewView={previewView}
          handleAssetTileClick={handleAssetTileClick}
        />
      )}
      <TaskMapModal
        open={openTaskMap}
        onClose={handleTaskMapClose}
        handleAddLocationClickFromMap={handleAddLocationClickFromMap}
        handleEditLocationClickFromMap={handleEditLocationClickFromMap}
        handleAddAssetClickFromMap={handleAddAssetClickFromMap}
        viewAssetId={viewAssetId}
        setViewAssetId={setViewAssetId}
      />
      <TaskLocationModal
        open={openTaskLocation}
        onClose={handleTaskLocationClose}
        location={editingLocation}
        editMode={editMode}
      />
      <TaskAssetModal open={openTaskAsset} onClose={handleTaskAssetClose} />
    </LocationsAndAssetsContext.Provider>
  );
};

export default LocationsAndAssetsSection;
