import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Divider } from '@mui/material';

// @ts-ignore
import taskTypeIcon from '@atom/components/common/svgIcons/taskType.svg';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_DELETE, TASK_DUPLICATE, TASK_UPDATE } from '@atom/graph/task';
import { GET_TIME_ENTRIES_STATUS } from '@atom/graph/timeEntry';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Button, Icon, Menu, Modal, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Task, TaskUpdateInput } from '@atom/types/task';
import { TaskTemplate } from '@atom/types/taskTemplate';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import { WorkOrderDetailType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { getValidationsFromError } from '@atom/utilities/workValidationUtilities';

import TaskCreateTemplateModal from '../taskCreate/TaskCreateTemplateModal';

import TaskTemplateCreationModal from './TaskTemplateCreationModal';

import './taskOptions.css';

const { MenuItem } = Menu;

const styles = {
  removeButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

interface Props {
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  refetch: () => Promise<any>;
  dispatch: any;
  WorkOrderActionTypes: any;
  onDelete?: () => void;
  dataCyLabel?: string;
}

const TaskHeaderOptions = ({
  workOrderDetail,
  task,
  dispatch,
  WorkOrderActionTypes,
  refetch,
  onDelete,
  dataCyLabel = '',
}: Props) => {
  const { setTask: setCurrentTask } = useContext(WorkOrderContext);
  const userProfile = useUserProfile();
  const preferences = usePreferences();
  const deleteOwnTask = preferences?.workOrders?.deleteOwnTask;
  const changeTaskTemplateEnabled =
    preferences?.workOrders?.changeTaskTemplateEnabled;

  const { setWorkValidations } = useWorkValidations();

  const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [confirmUpdateId, setConfirmUpdateId] = useState<string>(null);
  const [taskTemplateModalOpen, setTaskTemplateModalOpen] = useState<boolean>(
    false,
  );
  const [
    updateTaskTemplateModalOpen,
    setUpdateTaskTemplateModalOpen,
  ] = useState<boolean>(false);

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [taskUpdate, { loading: taskUpdateLoading }] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  const [deleteTask, { loading: loadingDelete }] = useMutation(TASK_DELETE);
  const [duplicateTask, { loading: loadingDuplicate }] = useMutation(
    TASK_DUPLICATE,
  );

  useEffect(() => {
    if (deleteModalOpen) {
      getTimeEntriesStatus({
        variables: {
          input: {
            taskId: task.id,
            workOrderId: workOrderDetail.id,
            statuses: [TimeEntryStatus.approved],
          },
        },
      });
    }
  }, [deleteModalOpen]);

  const setTask = (updatedTask: Task) => {
    dispatch({
      type: WorkOrderActionTypes.SET_TASK,
      data: {
        ...updatedTask,
      },
    });
  };

  const handleCompleteToggle = async () => {
    try {
      const newCompleteValue = !task.isCompleted;
      const completeText = newCompleteValue ? 'Complete' : 'Incomplete';

      Snackbar.info({ message: `Marking ${task.name} ${completeText}...` });

      const res = await taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            isCompleted: newCompleteValue,
          },
        },
      });

      setTask(res?.data?.taskUpdate);

      Snackbar.info({ message: `Marked ${task.name} ${completeText}.` });

      refetch();
    } catch (err) {
      if (err.networkError.statusCode === 422) {
        const taskValidations = getValidationsFromError(err);

        setWorkValidations({
          workOrderId: workOrderDetail.id,
          taskValidations,
        });
      }

      const message =
        err.networkError.statusCode === 422
          ? 'Unable to mark complete. Task required fields are missing.'
          : 'Something went wrong. Please try again or contact administrator.';

      Snackbar.error({ message });
    } finally {
      setCompleteModalOpen(false);
    }
  };

  const handleDuplicate = async () => {
    Snackbar.info({ message: `Duplicating ${task.name}...` });

    try {
      const { data } = await duplicateTask({
        variables: { workOrderId: workOrderDetail.id, taskId: task.id },
      });

      await refetch();

      Snackbar.info({ message: `Created ${data.taskDuplicate?.name}.` });
    } catch (error) {
      Snackbar.error({ message: `Failed to duplicate ${task.name}.` });
    }
  };

  const handleDelete = async () => {
    await deleteTask({
      variables: {
        workOrderId: workOrderDetail.id,
        taskId: task.id,
      },
    });

    Snackbar.info({
      message: 'Task deleted',
    });

    dispatch({
      type: WorkOrderActionTypes.DELETE_TASK,
      data: { taskId: task.id },
    });

    onDelete();
    setDeleteModalOpen(false);
    refetch();
  };

  const handleCreate = (template: TaskTemplate) => {
    setTaskTemplateModalOpen(false);

    Snackbar.info({
      message: `Task template created: ${template.name}`,
    });
  };

  const [
    taskTemplateUpdate,
    { loading: taskTemplateUpdateLoading },
  ] = useMutation<{ taskUpdate: Task }, { input: TaskUpdateInput }>(
    TASK_UPDATE,
    {
      onCompleted: () => {
        refetch();
        setConfirmUpdateId(null);
        setUpdateTaskTemplateModalOpen(false);
        setCurrentTask({});
        Snackbar.info({ message: 'Changed task template.' });
      },
      onError: () => {
        Snackbar.error({ message: 'Failed to change task template.' });
      },
    },
  );

  const handleUpdateTaskTemplate = async () => {
    Snackbar.info({ message: 'Changing task template...' });
    await taskTemplateUpdate({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          id: task.id,
          taskTemplateId: confirmUpdateId,
        },
      },
    });
  };

  const canDeleteOwnTask =
    deleteOwnTask &&
    userProfile.userId === task.createdBy &&
    !task?.isCompleted &&
    !workOrderDetail?.isClosed &&
    !workOrderDetail?.reopened;

  const isDeleteEnabled =
    !workOrderDetail.isClosed &&
    (canDeleteOwnTask
      ? hasRolePermissions(ROLE_SETS.INSPECTOR)
      : hasRolePermissions(ROLE_SETS.ORG_ADMIN));

  const isDuplicateEnabled =
    !workOrderDetail.isClosed && hasRolePermissions(ROLE_SETS.INSPECTOR);

  const isTaskTypeEnabled =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
    !isCurrentTenant([
      Tenant.UDOT,
      Tenant.UAT_UDOT,
      Tenant.QA_UDOT,
      Tenant.DEV_UDOT,
    ]);

  const isChangeTemplateEnabled =
    hasRolePermissions(ROLE_SETS.MANAGER) &&
    changeTaskTemplateEnabled &&
    !workOrderDetail.isClosed &&
    !task.isCompleted;

  const showDivider = isTaskTypeEnabled || isChangeTemplateEnabled;

  const hasApprovedTimeEntries =
    timeEntriesStatusData?.timeEntries?.totalCount > 0;

  const title = hasApprovedTimeEntries ? `Cannot Delete Task` : 'Delete task?';

  const content = hasApprovedTimeEntries
    ? 'Task with approved work time cannot be deleted.'
    : 'This will delete the task details, team members, asset association, and forms data.';

  const getFooter = () => {
    return hasApprovedTimeEntries ? (
      <Button onClick={() => setDeleteModalOpen(false)}>OK</Button>
    ) : (
      <div>
        <Button
          onClick={() => setDeleteModalOpen(false)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={timeEntriesStatusLoading || loadingDelete}
          onClick={handleDelete}
          style={styles.removeButton}
        >
          Remove
        </Button>
      </div>
    );
  };

  // TODO: [AM-7948]: Move to RBAC
  const showCompleteOption = hasRolePermissions(
    isCurrentTenant([Tenant.GWRR]) ? ROLE_SETS.MANAGER : ROLE_SETS.INSPECTOR,
  );

  const handleCompleteClick = () => {
    return task.isCompleted
      ? handleCompleteToggle()
      : setCompleteModalOpen(true);
  };

  const completeText = task.isCompleted ? 'Mark Incomplete' : 'Mark Complete';
  const completeIcon = task.isCompleted
    ? 'check_circle_outlined'
    : 'check_circle';

  return (
    <>
      <Menu dataCyLabel={dataCyLabel} IconButtonProps={{ edge: 'end' }}>
        {showCompleteOption && (
          <MenuItem
            onClick={handleCompleteClick}
            disabled={
              taskTemplateUpdateLoading ||
              taskUpdateLoading ||
              workOrderDetail.isClosed
            }
            startAdornment={<Icon>{completeIcon}</Icon>}
          >
            {completeText}
          </MenuItem>
        )}
        {isDuplicateEnabled && (
          <MenuItem
            onClick={handleDuplicate}
            disabled={loadingDuplicate}
            startAdornment={<Icon>content_copy</Icon>}
            data-cy="taskDuplicationButton"
          >
            Duplicate
          </MenuItem>
        )}
        {showDivider && <Divider />}
        {isTaskTypeEnabled && (
          <MenuItem
            startAdornment={
              <Icon>
                <img src={taskTypeIcon} />
              </Icon>
            }
            onClick={() => setTaskTemplateModalOpen(true)}
            data-cy="taskSaveButton"
          >
            Save as Task Template
          </MenuItem>
        )}
        {isChangeTemplateEnabled && (
          <MenuItem
            startAdornment={<Icon>swap_horiz</Icon>}
            onClick={() => setUpdateTaskTemplateModalOpen(true)}
            data-cy="taskUpdateTemplateButton"
          >
            Change Task Template
          </MenuItem>
        )}
        {showDivider && <Divider />}
        {isDeleteEnabled && (
          <MenuItem
            startAdornment={<Icon>delete</Icon>}
            onClick={() => setDeleteModalOpen(true)}
            data-cy="taskDeleteButton"
          >
            Delete
          </MenuItem>
        )}
      </Menu>
      <Modal
        open={deleteModalOpen}
        title={timeEntriesStatusLoading ? '' : title}
        footer={timeEntriesStatusLoading ? '' : getFooter()}
      >
        {timeEntriesStatusLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          content
        )}
      </Modal>
      <Modal
        open={completeModalOpen}
        loading={taskUpdateLoading || timeEntriesStatusLoading}
        confirmButtonText="Complete"
        onCancel={() => setCompleteModalOpen(false)}
        onConfirm={handleCompleteToggle}
        title="Complete Task?"
      >
        Not everyone completed the task. This action will complete the task for
        everyone on the task. Are you sure?
      </Modal>
      <Modal
        open={!isNilOrEmpty(confirmUpdateId)}
        loading={taskTemplateUpdateLoading || timeEntriesStatusLoading}
        confirmButtonText="Confirm"
        onCancel={() => setConfirmUpdateId(null)}
        onConfirm={handleUpdateTaskTemplate}
        title="Change Task Template?"
      >
        Task data will be transferred to the selected template. However, due to
        potential differences in task fields between the two templates, some
        data may not be carried over.
      </Modal>
      <TaskTemplateCreationModal
        open={taskTemplateModalOpen}
        onClose={() => setTaskTemplateModalOpen(false)}
        onCreate={handleCreate}
        workOrderDetail={workOrderDetail}
        task={task}
      />
      <TaskCreateTemplateModal
        workOrderId={workOrderDetail.id}
        open={updateTaskTemplateModalOpen}
        onClose={() => setUpdateTaskTemplateModalOpen(false)}
        handleConfirm={(property, taskTemplateId: string) =>
          setConfirmUpdateId(taskTemplateId)
        }
        disableDelete
        title="Choose a Task Template"
      />
    </>
  );
};

export default TaskHeaderOptions;
