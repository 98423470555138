import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import TaskHeaderOptions from '@atom/components/common/workOrderDetail/taskOptions/TaskHeaderOptions';
import UserStatusControls from '@atom/components/common/workOrderDetail/userStatusControls/UserStatusControls';
import WorkOrderContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { Modal, TextField } from '@atom/mui';
import { Task, TaskUpdateInput } from '@atom/types/task';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import InvalidFormModal from './InvalidFormModal';

import './taskHeader.css';

const TaskHeader = () => {
  const {
    workOrderDetail,
    task,
    dispatch,
    refetch,
    loading: workOrderLoading,
    setActiveTask,
  } = useContext(WorkOrderContext);

  const [open, setOpen] = useState<boolean>(false);
  const [renameOpen, setRenameOpen] = useState<boolean>(false);
  const [originalName, setOriginalName] = useState<string>(task?.originalName);

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  useEffect(() => {
    setOriginalName(task?.originalName);
  }, [task, task?.originalName]);

  const onRename = async () => {
    const res = await taskUpdate({
      variables: {
        input: {
          id: task?.id,
          workOrderId: workOrderDetail?.id,
          name: originalName,
        },
      },
    });

    const name = res?.data?.taskUpdate?.name;
    const newOriginalName = res?.data?.taskUpdate?.originalName;

    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task?.id,
        property: 'name',
        value: name,
      },
    });

    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task?.id,
        property: 'originalName',
        value: newOriginalName,
      },
    });

    setRenameOpen(false);
  };

  const onDelete = () => {
    setActiveTask('');
  };

  // Specific rename permission for ALDOT related tenants
  const aldotRenamePermissions =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
    !task?.taskTemplateId &&
    !workOrderDetail.isClosed;
  const basicRenamePermissions =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && !workOrderDetail.isClosed;

  const canRenameTask = isCurrentClient([Client.ALDOT])
    ? aldotRenamePermissions
    : basicRenamePermissions;

  const taskNameStyles = canRenameTask
    ? 'task-header-title-hover'
    : 'task-header-title';

  const onNameClick = canRenameTask ? () => setRenameOpen(true) : () => {};

  return (
    <div styleName="task-header-container">
      <div styleName={taskNameStyles} onClick={onNameClick}>
        {task?.name}
      </div>
      <div styleName="action-buttons-container">
        <UserStatusControls
          workOrderDetail={workOrderDetail}
          workOrderLoading={workOrderLoading}
          task={task}
          refetch={refetch}
        />
        <InvalidFormModal open={open} closeModal={() => setOpen(false)} />
        <TaskHeaderOptions
          workOrderDetail={workOrderDetail}
          task={task}
          dispatch={dispatch}
          refetch={refetch}
          WorkOrderActionTypes={WorkOrderActionTypes}
          onDelete={onDelete}
          dataCyLabel="taskAdditionalActions"
        />
        <Modal
          open={renameOpen}
          cancelButtonText="Cancel"
          confirmButtonText="Save"
          onCancel={() => setRenameOpen(false)}
          onConfirm={onRename}
          disabled={!originalName}
          title="Rename Task"
        >
          <TextField
            label="Task Name *"
            value={originalName}
            name="name"
            onChange={event => setOriginalName(event.target.value)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default TaskHeader;
