import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import { getLatLng } from '@atom/utilities/locationUtilities';

import CreateAssetModalContext from '../CreateAssetModalContext';

import LocationInputs from './LocationInputs';
import PointLocationTabMap from './PointLocationTabMap';

import './pointLocationTab.css';

const LocationTab = () => {
  const { location, setAddress } = useContext(CreateAssetModalContext);

  const [map, setMap] = useState<google.maps.Map>(null);
  const [geocoder, setGeocoder] = useState<any>();

  const onLoad = useCallback(mapData => {
    setMap(mapData);
    // Once the map is rendered, access the Geocoder from the global
    // google maps object and set value.
    setGeocoder(new window.google.maps.Geocoder());
  }, []);

  useEffect(() => {
    if (location && geocoder) {
      geocoder.geocode(
        { location: getLatLng(location.coordinates) },
        results => {
          // The first result is the closest match for reverse geocode
          const bestMatchAddress = R.pathOr(
            '',
            [0, 'formatted_address'],
            results,
          );

          setAddress(bestMatchAddress);
        },
      );
    }
  }, [location, geocoder]);

  return (
    <div styleName="location-tab-container">
      <div>
        <div styleName="tab-title">Location</div>
        <div styleName="title-subtext">
          Provide a location by entering an address, coordinates, or clicking
          directly on the map.
        </div>
        {map && <LocationInputs />}
      </div>
      <div styleName="map-container">
        <PointLocationTabMap onLoad={onLoad} map={map} />
      </div>
    </div>
  );
};

export default LocationTab;
