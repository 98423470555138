import { gql } from '@apollo/client';

export const BASE_BUDGET = gql`
  fragment BaseBudget on Budget {
    id
    name
    startDate
    endDate
    totalBudget
    actualBudget
    status
    remaining
    fixedCosts
    templateId
    templateName
    rootBudgetUnitId
    createdBy {
      id
      firstName
      lastName
    }
    createdDate
  }
`;

export const BASE_BASIC_BUDGET = gql`
  fragment BaseBasicBudget on BasicBudget {
    id
    name
    startDate
    endDate
    status
  }
`;

export const GET_BUDGETS = gql`
  query budgets($input: BudgetsConnectionInput) {
    budgets(input: $input) {
      totalCount
      budgets {
        ...BaseBudget
      }
    }
  }
  ${BASE_BUDGET}
`;

export const GET_BUDGET_OPTIONS = gql`
  query budgetOptions($input: BudgetsConnectionInput) {
    budgetOptions(input: $input) {
      totalCount
      budgets {
        ...BaseBasicBudget
      }
    }
  }
  ${BASE_BASIC_BUDGET}
`;

export const SEARCH_BUDGET_OPTIONS = gql`
  query budgetSearch($input: BudgetSearchInput!) {
    budgetSearch(input: $input) {
      totalCount
      budgets {
        ...BaseBasicBudget
      }
    }
  }
  ${BASE_BASIC_BUDGET}
`;

export const GET_BUDGET_SUMMARIES = gql`
  query budgetSummary($input: BudgetSummaryConnectionInput!) {
    budgetSummary(input: $input) {
      totalCount
      budgetSummary {
        id
        name
        budgetValue
        actualBudget
        remaining
        fixedCosts
        comment
        startDate
        endDate
      }
    }
  }
`;

export const GET_BUDGET = gql`
  query budget($id: ID!) {
    budget(id: $id) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const CREATE_BUDGET = gql`
  mutation budgetCreate($input: BudgetCreateInput!) {
    budgetCreate(input: $input) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const UPDATE_BUDGET = gql`
  mutation budgetUpdate($input: BudgetUpdateInput!) {
    budgetUpdate(input: $input) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const DUPLICATE_BUDGET = gql`
  mutation budgetDuplicate($input: BudgetDuplicateInput!) {
    budgetDuplicate(input: $input) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const DELETE_BUDGET = gql`
  mutation budgetDelete($id: ID!) {
    budgetDelete(id: $id)
  }
`;

export const GET_BUDGET_TEMPLATES = gql`
  query budgetTemplates {
    budgetTemplates {
      totalCount
      budgetTemplates {
        id
        name
      }
    }
  }
`;

export const GET_BUDGET_CATEGORIES = gql`
  query budgetCategories($input: BudgetCategoriesConnectionInput!) {
    budgetCategories(input: $input) {
      totalCount
      budgetCategories {
        id
        name
        totalBudget
        actualBudget
        remaining
        fixedCosts
        budgetItems {
          id
          name
          budgetValue
          comment
          costType
          actualBudget
          remaining
          fixedCosts
        }
      }
    }
  }
`;

export const SEARCH_BUDGET_UNITS = gql`
  query budgetUnitSearch($input: BudgetUnitSearchInput!) {
    budgetUnitSearch(input: $input) {
      totalCount
      budgetUnits {
        id
        name
        ancestors {
          name
          id
        }
      }
    }
  }
`;

export const GET_BUDGET_UNITS = gql`
  query budgetUnits($input: BudgetUnitsConnectionInput!) {
    budgetUnits(input: $input) {
      totalCount
      budgetUnits {
        id
        name
        ancestors
        templateId
        totalBudget
        actualBudget
        remaining
        fixedCosts
        ancestors
        hasBudgetItems
        status
      }
    }
  }
`;

export const UPDATE_BUDGET_UNITS = gql`
  mutation budgetUnitUpdate($input: BudgetUnitUpdateInput!) {
    budgetUnitUpdate(input: $input)
  }
`;

export const GET_BUDGET_UNIT = gql`
  query budgetUnit($input: BudgetUnitConnectionInput!) {
    budgetUnit(input: $input) {
      id
      name
      templateId
      totalBudget
      actualBudget
      remaining
      fixedCosts
      ancestors
      hasBudgetItems
      actions
      status
      grants {
        ids
        type
      }
    }
  }
`;

export const GET_BASIC_BUDGET_UNIT = gql`
  query basicBudgetUnit($input: BudgetUnitConnectionInput!) {
    basicBudgetUnit(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_BUDGET_ITEM = gql`
  mutation budgetItemUpdate($input: BudgetItemUpdateInput!) {
    budgetItemUpdate(input: $input) {
      id
      name
      budgetId
      budgetCategoryId
      budgetItemTemplateId
      budgetValue
      comment
      costType
    }
  }
`;

export const GET_BUDGET_CATEGORY_FILTERS = gql`
  query budgetCategoriesFilter($input: BudgetCategoriesFilterConnectionInput!) {
    budgetCategoriesFilter(input: $input) {
      totalCount
      budgetCategories {
        id
        name
      }
    }
  }
`;

export const GET_BUDGET_ITEM_TEMPLATE_FILTERS = gql`
  query budgetItemTemplatesFilter(
    $input: BudgetItemTemplatesFilterConnectionInput!
  ) {
    budgetItemTemplatesFilter(input: $input) {
      totalCount
      budgetItemTemplates {
        id
        name
      }
    }
  }
`;

export const GET_BUDGET_UNIT_TREE = gql`
  query budgetUnitTree($id: ID!) {
    budgetUnitTree(id: $id) {
      id
      name
      unitPath
      children
    }
  }
`;

export const GET_BUDGET_UNIT_TEMPLATE_TREE = gql`
  query budgetUnitTreeForTemplateId($id: ID!) {
    budgetUnitTreeForTemplateId(id: $id) {
      id
      name
      unitPath
      children
      hasItems
      itemsCount
    }
  }
`;

export const GET_BUDGET_CHARTS = gql`
  query budgetCharts($input: BudgetChartsConnectionInput!) {
    budgetCharts(input: $input) {
      totalCount
      budgetCharts {
        budgetId
        budgetName
        charts {
          month
          actualCost
          totalRemaining
        }
      }
    }
  }
`;
