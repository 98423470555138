import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import { updateWorkOrdersInput } from '@atom/actions/workOrdersInputActions';
import HistoryDrawer from '@atom/components/common/history/HistoryDrawer';
import PriorityControl from '@atom/components/common/PriorityControl';
import WorkOrderDetailEdit from '@atom/components/common/workOrderDetail/workOrderDetailEdit/WorkOrderDetailEdit';
import WorkOrderStatusEdit from '@atom/components/common/workOrderDetail/workOrderStatusEdit/WorkOrderStatusEdit';
import { GET_TIME_ENTRIES_STATUS } from '@atom/graph/timeEntry';
import {
  WORK_ORDER_DELETE,
  WORK_ORDER_DUPLICATE,
  WORK_ORDER_UPDATE,
} from '@atom/graph/work';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  Button,
  Icon,
  IconButton,
  Menu,
  Modal,
  Progress,
  Snackbar,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import { ReportTypes } from '@atom/types/preferences';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import {
  WorkOrderDetailType,
  WorkOrderTab,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { CLOSED_STATUS_ID } from '@atom/utilities/workOrderStatusUtilities';

import WorkOrderContext, { WorkOrderActionTypes } from '../WorkOrderContext';

import DetailHeaderButton from './DetailHeaderButton';
import HeaderInfoSection from './HeaderInfoSection';
import ReportModal from './ReportModal';
import WorkOrderVersionButton from './WorkOrderVersionButton';

import './workOrderDetailHeader.css';

const { MenuItem } = Menu;

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
  name: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  removeButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

const views = [
  {
    value: WorkOrderTab.INFO,
    label: 'Info',
    icon: 'info',
    dataCy: 'workInfoTab',
  },
  {
    value: WorkOrderTab.TASKS,
    label: 'Tasks',
    icon: 'playlist_add_check',
    dataCy: 'workTasksTab',
  },
  {
    value: WorkOrderTab.TEAM,
    label: 'Team',
    icon: 'supervisor_account',
    dataCy: 'workTeamTab',
  },
  {
    value: WorkOrderTab.DOCUMENTS,
    label: 'Documents',
    icon: 'description',
    dataCy: 'workDocumentsTab',
  },
  {
    value: WorkOrderTab.PHOTOS,
    label: 'Photos',
    icon: 'insert_photo',
    dataCy: 'workPhotosTab',
  },
  {
    value: WorkOrderTab.VIDEOS,
    label: 'Videos',
    icon: 'video_library',
    dataCy: 'workVideosTab',
  },
];

export interface Props {
  activeView: WorkOrderTab;
  setActiveView: (view: WorkOrderTab) => void;
}

const DetailHeader = ({ activeView, setActiveView }: Props) => {
  const container = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const userProfile = useUserProfile();
  const preferences = usePreferences();
  const deleteOwnWork = preferences?.workOrders?.deleteOwnWork;
  const reportTypes = preferences?.reports || [];

  const input = useSelector(state => state.workOrdersInput);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);

  const {
    workOrderDetail,
    loading,
    dispatch: contextDispatch,
    refetch,
  } = useContext(WorkOrderContext);

  const [duplicateWorkOrder, { loading: loadingDuplicate }] = useMutation(
    WORK_ORDER_DUPLICATE,
  );

  const [deleteWorkOrder, { loading: loadingDelete }] = useMutation(
    WORK_ORDER_DELETE,
  );

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (deleteModalOpen || duplicateModalOpen) {
      getTimeEntriesStatus({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            statuses: [TimeEntryStatus.approved],
          },
        },
      });
    }
  }, [deleteModalOpen, duplicateModalOpen]);

  const [updateWorkDetail] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const updateWorkOrderProperty = (
    property: keyof WorkOrderDetailType,
    value: any,
  ) => {
    contextDispatch({
      type: WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY,
      data: {
        property,
        value,
      },
    });
  };

  const updateWorkOrder = async params => {
    const { priorityId } = params;

    if (R.isNil(priorityId)) {
      return;
    }

    const res = await updateWorkDetail({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          priorityId,
        },
      },
    });

    updateWorkOrderProperty(
      'priorityId',
      res?.data?.workOrderUpdate.priorityId,
    );
  };

  const navigateBackToWorkOrders = () => {
    history.goBackWithState('/workOrders');
  };

  const handleDeleteWorkOrder = async () => {
    const state = history.location.state;

    try {
      await deleteWorkOrder({
        variables: {
          id: workOrderDetail.id,
        },
      });

      dispatch(updateWorkOrdersInput({ ...input, workOrderId: undefined }));

      history.push('/workOrders', state);

      Snackbar.info({
        message: 'Work deleted',
      });
    } catch (error) {
      const message =
        error?.networkError?.statusCode === 423
          ? 'Cannot delete a work used to create a work template.'
          : 'An error occurred. Please try again.';

      Snackbar.error({ message });

      setDeleteModalOpen(false);
    }
  };

  const handleDuplicate = async () => {
    const { id, name } = workOrderDetail;

    Snackbar.info({ message: `Duplicating work ${name}...` });

    try {
      const { data } = await duplicateWorkOrder({ variables: { id } });

      Snackbar.info({
        message: `Duplicated work ${name}.`,
        action: 'View',
        onActionClick: () =>
          history.push(`/workOrders/${data.workOrderDuplicate}`),
      });
    } catch (error) {
      Snackbar.error({ message: `Failed to duplicate work ${name}.` });
    }

    setDuplicateModalOpen(false);
  };

  const hasApprovedTimeEntries =
    timeEntriesStatusData?.timeEntries?.totalCount > 0;

  const getDeleteModalValue = () => {
    return hasApprovedTimeEntries
      ? {
          title: `Cannot Delete Work`,
          content: 'Work with approved work time cannot be deleted.',
        }
      : {
          title: 'Delete Work',
          content: 'Are you sure you want to delete this work?',
        };
  };

  const getDuplicateModalValue = () => {
    return {
      title: `Duplicate Work`,
      content: 'Are you sure you want to duplicate this work?',
    };
  };

  const deleteModalLoading = loadingDelete || timeEntriesStatusLoading;
  const duplicateModalLoading = loadingDuplicate || timeEntriesStatusLoading;

  const getDeleteFooter = () => {
    return hasApprovedTimeEntries ? (
      <Button onClick={() => setDeleteModalOpen(false)}>OK</Button>
    ) : (
      <div>
        <Button
          onClick={() => setDeleteModalOpen(false)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={deleteModalLoading}
          onClick={handleDeleteWorkOrder}
          style={styles.removeButton}
        >
          Delete
        </Button>
      </div>
    );
  };

  const getDuplicateFooter = () => {
    return (
      <div>
        <Button
          onClick={() => setDuplicateModalOpen(false)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={duplicateModalLoading}
          onClick={handleDuplicate}
          color="primary"
        >
          Duplicate
        </Button>
      </div>
    );
  };

  const hasAsset = !R.isEmpty(workOrderDetail.inventoryAssetId);

  const canDeleteOwnWork =
    deleteOwnWork &&
    userProfile.userId === workOrderDetail.createdBy &&
    workOrderDetail?.statusId !== CLOSED_STATUS_ID &&
    !workOrderDetail?.reopened;

  const isDeleteEnabled = canDeleteOwnWork
    ? hasRolePermissions(ROLE_SETS.INSPECTOR)
    : isCurrentTenant([
        Tenant.DEV_HENNEPIN,
        Tenant.QA_HENNEPIN,
        Tenant.UAT_HENNEPIN,
        Tenant.HENNEPIN,
      ])
    ? hasRolePermissions(ROLE_SETS.MANAGER)
    : hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  const isDuplicateEnabled = hasRolePermissions(ROLE_SETS.INSPECTOR);
  const showActionMenu = isDeleteEnabled || isDuplicateEnabled;

  const workNameWidth = `${container.current?.offsetWidth - 400}px`;

  const deleteModalValue = getDeleteModalValue();
  const duplicateModalValue = getDuplicateModalValue();

  const showReportModal =
    (hasAsset ||
      R.intersection(
        [
          ReportTypes.CUSTOM_WORK_REPORT,
          ReportTypes.ALDOT_REPORT,
          ReportTypes.SCDOT_REPORT,
        ],
        reportTypes,
      ).length > 0) &&
    !isNilOrEmpty(reportTypes);

  // User can version their own work if the current client is Hennepin
  // and work is created by logged in user.
  const canVersionOwnWork =
    isCurrentClient([Client.HENNEPIN]) &&
    userProfile?.userId === workOrderDetail.createdBy;

  const showVersionButton =
    workOrderDetail.isClosed &&
    hasAsset &&
    (canVersionOwnWork
      ? hasRolePermissions(ROLE_SETS.INSPECTOR)
      : hasRolePermissions(ROLE_SETS.MANAGER));

  return (
    <div ref={container} styleName="header-container">
      <div>
        <IconButton onClick={navigateBackToWorkOrders} tooltip="Go back">
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <div style={{ width: workNameWidth }} styleName="title-bar">
          <span style={styles.name}>Work ID: {workOrderDetail.name}</span>
        </div>
      </div>
      <div styleName="icon-bar">
        {loading && (
          <div styleName="loading-container">
            <Progress
              size={20}
              thickness={2}
              progressColor={colors.neutral.white}
            />
          </div>
        )}
        <WorkOrderStatusEdit
          workOrder={workOrderDetail}
          createdById={workOrderDetail?.createdBy}
          refetch={refetch}
          styleType="detail"
        />
        <PriorityControl
          disabled={workOrderDetail.isClosed}
          priorityId={workOrderDetail.priorityId}
          updateWorkOrder={updateWorkOrder}
        />
        {showReportModal && <ReportModal />}
        {showVersionButton && <WorkOrderVersionButton />}
        {!workOrderDetail.isClosed && (
          <WorkOrderDetailEdit
            workOrderDetail={workOrderDetail}
            refetch={refetch}
            iconColor={colors.neutral.white}
          />
        )}
        <HistoryDrawer subject="workOrders" id={workOrderDetail.id} />
        {showActionMenu && (
          <Menu
            IconProps={{ color: colors.neutral.white }}
            IconButtonProps={{ edge: 'end' }}
            data-cy="workOrderDetailOptions"
          >
            {isDuplicateEnabled && (
              <MenuItem
                startAdornment={<Icon style={styles.icon}>content_copy</Icon>}
                onClick={() => setDuplicateModalOpen(true)}
                disabled={loadingDuplicate}
                data-cy="workOrderDetailDuplicateWork"
              >
                Duplicate
              </MenuItem>
            )}
            {isDeleteEnabled && (
              <MenuItem
                onClick={() => setDeleteModalOpen(true)}
                startAdornment={<Icon style={styles.icon}>delete</Icon>}
                data-cy="workOrderDetailDeleteWork"
              >
                Delete
              </MenuItem>
            )}
          </Menu>
        )}
      </div>
      <HeaderInfoSection />
      <div styleName="icon-row">
        {views.map(view => {
          return (
            <DetailHeaderButton
              key={view.value}
              view={view}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          );
        })}
      </div>
      <Modal
        open={deleteModalOpen}
        title={deleteModalLoading ? '' : deleteModalValue.title}
        footer={deleteModalLoading ? '' : getDeleteFooter()}
        data-cy="workOrderDeleteModal"
      >
        {deleteModalLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          deleteModalValue.content
        )}
      </Modal>
      <Modal
        open={duplicateModalOpen}
        title={duplicateModalLoading ? '' : duplicateModalValue.title}
        footer={duplicateModalLoading ? '' : getDuplicateFooter()}
        data-cy="workOrderDuplicateModal"
      >
        {duplicateModalLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          duplicateModalValue.content
        )}
      </Modal>
    </div>
  );
};

export default DetailHeader;
