import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { TASK_USERS_STATUS_UPDATE } from '@atom/graph/task';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Button, Icon, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  Task,
  TaskUsersStatusUpdateInput,
  TaskUserStatus,
} from '@atom/types/task';
import { WorkOrderDetailType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getTaskUser } from '@atom/utilities/taskUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { getValidationsFromError } from '@atom/utilities/workValidationUtilities';

import CheckInModal from './CheckInModal';
import HelpModal from './HelpModal';
import RejectModal from './RejectModal';

import './userStatusControls.css';

const styles = {
  redButton: {
    marginLeft: '0.5rem',
    color: colors.brand.red,
  },
  blueButton: {
    marginLeft: '0.5rem',
    color: colors.brand.blue,
  },
};

interface Props {
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  refetch: () => Promise<any>;
  workOrderLoading: boolean;
}

const UserStatusControls = ({
  workOrderDetail,
  task,
  refetch,
  workOrderLoading,
}: Props) => {
  const userProfile = useUserProfile();

  const { setWorkValidations } = useWorkValidations();

  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [openCheckInModal, setOpenCheckInModal] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const user = getTaskUser(userProfile.userId, task);

  const isCompleted = user?.status === TaskUserStatus.COMPLETED;
  const isAssigned = user?.status === TaskUserStatus.ASSIGNED;
  const isInProgress = user?.status === TaskUserStatus.IN_PROGRESS;

  const [
    updateUsersTaskStatus,
    { loading: updateUsersTaskStatusLoading },
  ] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const toggleComplete = async () => {
    const status = isCompleted
      ? TaskUserStatus.IN_PROGRESS
      : TaskUserStatus.COMPLETED;

    try {
      await updateUsersTaskStatus({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            users: [
              {
                userId: user.id,
                status,
              },
            ],
          },
        },
      });
    } catch (err) {
      if (err.networkError.statusCode === 422) {
        const taskValidations = getValidationsFromError(err);

        setWorkValidations({
          workOrderId: workOrderDetail.id,
          taskValidations,
        });
      }

      const message =
        err.networkError.statusCode === 422
          ? 'Unable to mark complete. Task required fields are missing.'
          : 'Something went wrong. Please try again or contact administrator.';

      Snackbar.error({ message });
    }
  };

  const completedColor = isCompleted ? colors.brand.green : colors.brand.blue;
  const completedIcon = isCompleted ? 'check_circle' : 'check_circle_outlined';
  const completedText = isCompleted ? 'Completed' : 'Complete';

  const showRejectButton = !isNilOrEmpty(user);
  const showCheckInButton =
    (isNilOrEmpty(user) || isAssigned) &&
    hasRolePermissions(ROLE_SETS.INSPECTOR);
  const showCompleteButton =
    (isInProgress || isCompleted) && hasRolePermissions(ROLE_SETS.INSPECTOR);

  const loading =
    workOrderLoading || loadingModal || updateUsersTaskStatusLoading;

  const isDisabled = loading || workOrderDetail.isClosed;

  return (
    <>
      {loading && (
        <div>
          <Progress size={16} thickness={5} />
        </div>
      )}
      {showRejectButton && (
        <Button
          variant="outlined"
          style={styles.redButton}
          startIcon={<Icon color={colors.brand.red}>close</Icon>}
          disabled={isDisabled}
          onClick={() => setOpenRejectModal(true)}
        >
          Reject
        </Button>
      )}
      <Button
        variant="outlined"
        style={styles.blueButton}
        startIcon={<Icon color={colors.brand.blue}>outlined_flag</Icon>}
        disabled={isDisabled}
        onClick={() => setOpenHelpModal(true)}
      >
        Help
      </Button>
      {showCheckInButton && (
        <Button
          variant="outlined"
          style={styles.blueButton}
          startIcon={<Icon color={colors.brand.blue}>person_pin_circle</Icon>}
          disabled={isDisabled}
          onClick={() => setOpenCheckInModal(true)}
          data-cy="userCheckIn"
        >
          Check In
        </Button>
      )}
      {showCompleteButton && (
        <Button
          variant="outlined"
          style={{ marginLeft: '0.5rem', color: completedColor }}
          startIcon={<Icon color={completedColor}>{completedIcon}</Icon>}
          disabled={isDisabled}
          onClick={toggleComplete}
        >
          {completedText}
        </Button>
      )}
      <RejectModal
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        loading={loadingModal}
        setLoading={setLoadingModal}
        workOrderId={workOrderDetail.id}
        taskId={task.id}
        refetch={refetch}
      />
      <HelpModal
        open={openHelpModal}
        onClose={() => setOpenHelpModal(false)}
        loading={loadingModal}
        setLoading={setLoadingModal}
        workOrderId={workOrderDetail.id}
        taskId={task.id}
      />
      <CheckInModal
        open={openCheckInModal}
        onClose={() => setOpenCheckInModal(false)}
        taskUserStatus={user?.status}
        workOrderId={workOrderDetail.id}
        taskId={task.id}
        refetch={refetch}
      />
    </>
  );
};

export default UserStatusControls;
