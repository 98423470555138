import React, { useContext } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import LocationsAndAssetsSection from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsSection';
import TaskFields from '@atom/components/common/workOrderDetail/taskFields/TaskFields';
import WorkOrderContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderDetail/WorkOrderContext';
import { Icon } from '@atom/mui';
import { WorkOrderType } from '@atom/types/work';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import AssetSection from './assetSection/AssetSection';
import FormSection from './formSection/FormSection';
import MaterialSection from './materialSection/MaterialSection';
import TaskHeader from './taskHeader/TaskHeader';
import TeamSection from './teamSection/TeamSection';
import TaskActualCost from './TaskActualCost';
import TaskCompletedDate from './TaskCompletedDate';
import TaskDescription from './TaskDescription';
import TaskDueDate from './TaskDueDate';
import TaskDuration from './TaskDuration';
import TaskEstimatedCost from './TaskEstimatedCost';
import TaskStartDate from './TaskStartDate';
import TaskWorkTime from './TaskWorkTime';

import './taskDetailView.css';

const styles = {
  icon: {
    paddingTop: '8px',
  },
};

const TaskDetailView = () => {
  const [queryParams] = useQueryParams({
    task: StringParam,
  });

  const { workOrderDetail, task, dispatch, refetch } = useContext(
    WorkOrderContext,
  );

  const showAssetSection =
    workOrderDetail.inventoryAssetId &&
    workOrderDetail?.type === WorkOrderType.DEFAULT;
  const showLocationsAndAssetsSection =
    workOrderDetail?.type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS &&
    (task?.locationsEnabled || task?.multiAssetsEnabled);

  const showDescription = !isCurrentClient([Client.ALDOT]);

  return (
    <div styleName="task-container">
      <TaskHeader />
      <div styleName="content-container">
        <div styleName="left-column">
          <div styleName="time-detail-row">
            <Icon style={styles.icon}>today</Icon>
            <div styleName="time-row-container">
              <TaskDueDate />
              <TaskStartDate />
              <TaskCompletedDate />
              <TaskWorkTime />
              <TaskDuration />
            </div>
          </div>
          {showDescription && (
            <div styleName="task-detail-row">
              <TaskDescription />
            </div>
          )}
          <TaskFields
            workOrderDetail={workOrderDetail}
            task={task}
            dispatch={dispatch}
            WorkOrderActionTypes={WorkOrderActionTypes}
            refetch={refetch}
          />
          {showAssetSection && <AssetSection />}
          {showLocationsAndAssetsSection && (
            <LocationsAndAssetsSection
              workOrderDetail={workOrderDetail}
              task={task}
              currentTaskId={queryParams?.task}
              refetch={refetch}
            />
          )}
          <FormSection />
        </div>
        <div styleName="right-column">
          <div styleName="cost-container">
            <TaskEstimatedCost />
            <TaskActualCost />
          </div>
          <TeamSection />
          <MaterialSection />
        </div>
      </div>
    </div>
  );
};

export default TaskDetailView;
