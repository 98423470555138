import React, { useContext, useState } from 'react';
import * as R from 'ramda';

// @ts-ignore
import versionIcon from '@atom/components/common/svgIcons/versionIcon.svg';
import { Icon, IconButton, Modal, Snackbar } from '@atom/mui';
import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

import WorkOrderContext from '../WorkOrderContext';

import './workOrderDetailHeader.css';

const WorkOrderVersionButton = () => {
  const { workOrderDetail } = useContext(WorkOrderContext);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const applyHistorySnapshot = async () => {
    const {
      id,
      inventoryAssetName,
      inventoryAssetId,
      formInstances,
    } = workOrderDetail;
    setLoading(true);

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${id}/apply`;

    const formInstanceIds = R.keys(formInstances);
    const body = {
      inventoryAssetName,
      rootAssetId: inventoryAssetId,
      ...(!R.isEmpty(formInstanceIds) && { formInstanceIds }),
    };

    try {
      await api.post(endpoint, body);

      Snackbar.info({
        message: `Asset ${inventoryAssetName} updated.`,
        action: 'View',
        onActionClick: () => history.push(`/inventory/${inventoryAssetId}`),
      });
    } catch (error) {
      Snackbar.error({
        message: 'Could not version asset',
      });
    }

    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <IconButton
        tooltip="Version Asset"
        edge="end"
        onClick={() => setOpen(true)}
      >
        <Icon>
          <img src={versionIcon} />
        </Icon>
      </IconButton>
      <Modal
        title={`Update asset ${workOrderDetail.inventoryAssetName}?`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={applyHistorySnapshot}
        confirmButtonText="Update"
        loading={loading}
      >
        <div styleName="apply-history-modal-content">
          <div>
            This will update asset
            <span styleName="strong">{workOrderDetail.inventoryAssetName}</span>
            with work <span styleName="strong">{workOrderDetail.name}</span>
            data. Documents, photos and videos in this work will transfer to
            asset
            <span styleName="strong">
              {workOrderDetail.inventoryAssetName}.
            </span>
          </div>
          <div styleName="modal-subtext">
            Please note that versioning will update the asset regardless of
            attribute settings that make values required or uneditable.
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WorkOrderVersionButton;
