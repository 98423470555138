import React from 'react';

import colors from '@atom/styles/colors';

export interface RechartPayload {
  dataKey: string;
  chartType?: string;
  color?: string;
  fill?: string;
  fillOpacity?: number;
  formatter?;
  name?: string;
  payload?: any;
  value?: number;
}

export const LINE_ANIMATION_MS = 250;
export const BAR_ANIMATION_MS = 0;
export const CHART_HEIGHT = 24; // in rem
export const CHART_TOP_MARGIN = 20;

export enum GroupKeys {
  ACTUAL = 'Actual Cost',
  REMAINING = 'Total Remaining',
}

// Using methods to return svg for direct use inside rechart components
export const LineIcon = color => (
  <svg width="14" height="14" viewBox="0 0 32 32">
    <path
      strokeWidth="4"
      fill="none"
      stroke={color}
      d="M0,16h10.666666666666666 A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16 H32M21.333333333333332,16 A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
    />
  </svg>
);

export const BarIcon = color => (
  <svg width="14" height="14" viewBox="0 0 32 32">
    <path stroke="none" fill={color} d="M0,4h32v24h-32z" />
  </svg>
);

export const yAxisTickFormatter = (tickVal, maxVal) => {
  switch (true) {
    case maxVal >= 1000000000:
      return `$${Math.round((tickVal / 1000000000) * 2) / 2}B`;
    case maxVal >= 1000000:
      return `$${Math.round((tickVal / 1000000) * 2) / 2}M`;
    case maxVal >= 1000:
      return `$${Math.round((tickVal / 1000) * 2) / 2}K`;
    default:
      return `$${Math.round(tickVal * 2) / 2}`;
  }
};

const colorCycle = [
  '#00BCD4',
  '#B99AFF',
  '#4A18BD',
  '#1D905B',
  '#FF7537',
  '#FBC02D',
];

export const getChartColor = idx => colorCycle[idx % colorCycle.length];

export const renderLegendText = (value: string) => {
  const label = value.endsWith('Actual Cost')
    ? value.split('Actual Cost')[0]
    : value.split('Total Remaining')[0];
  return (
    <span style={{ color: colors.neutral.black }}>
      {label.slice(0, 16)}
      {label.length >= 16 ? '...' : ''}
    </span>
  );
};

// dateLabel is a string in the form `${month} ${year}` from query budgetCharts
export const getMonthOnly = (dateLabel: string): string => {
  return dateLabel?.split(' ')[0] || '';
};
