import React from 'react';
import { Control } from 'react-redux-form';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TextField from '../common/TextField';

import textStyle from './textStyle';

import './attributeControls.css';

interface Props {
  name: string;
  value: any;
  isEditable: boolean;
  onUpdate?: (hasUnsavedChanges: boolean) => void;
}

interface State {
  value: any;
}

class CurrencyAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { value: !isNilOrEmpty(props.value) ? props.value : '' };
  }

  onChange = event => {
    if (this.props.onUpdate) {
      this.props.onUpdate(true);
    }
    this.setState({ value: event.target.value });
  };

  render() {
    const { name, isEditable } = this.props;
    const { value } = this.state;

    return (
      <div styleName="currency-container" key={name}>
        <Control
          type="number"
          step="any"
          model={`.${name}`}
          component={TextField}
          value={value}
          disabled={!isEditable}
          onChange={event => this.onChange(event)}
          controlProps={{
            fullWidth: true,
            textareaStyle: textStyle.textarea,
            style: textStyle.inputText,
          }}
        />
      </div>
    );
  }
}

export default CurrencyAttribute;
