import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import { getGoogleShapeCenter } from '@atom/utilities/mapUtilities';

import CreateAssetModalContext from '../CreateAssetModalContext';

import LineLocationTabMap from './LineLocationTabMap';

import './lineLocationTab.css';

const LineLocationTab = () => {
  const { location, setAddress, address, setLocation } = useContext(
    CreateAssetModalContext,
  );

  const [map, setMap] = useState<google.maps.Map>(null);
  const [geocoder, setGeocoder] = useState<any>();
  const [clearDrawing, setClearDrawing] = useState<boolean>(false);
  const [displayCoordinates, setDisplayCoordinates] = useState<any>();

  const onLoad = useCallback(mapData => {
    setMap(mapData);
    // Once the map is rendered, access the Geocoder from the global
    // google maps object and set value.
    setGeocoder(new window.google.maps.Geocoder());
  }, []);

  useEffect(() => {
    if (location && geocoder) {
      const centerLocation = getGoogleShapeCenter(location);
      setDisplayCoordinates(centerLocation);

      geocoder.geocode({ location: centerLocation }, results => {
        // The first result is the closest match for reverse geocode
        const bestMatchAddress = R.pathOr(
          '',
          [0, 'formatted_address'],
          results,
        );

        setAddress(bestMatchAddress);
      });
    }
  }, [location, geocoder]);

  const handleClear = () => {
    setAddress(null);
    setLocation(null);
    setDisplayCoordinates(null);
    setClearDrawing(true);
  };

  const coordinatesSubtext = displayCoordinates
    ? `${displayCoordinates?.lat.toFixed(6)}, ${displayCoordinates?.lng.toFixed(
        6,
      )}`
    : '';

  return (
    <div styleName="location-tab-container">
      <div>
        <div styleName="tab-title">Location</div>
        {R.isNil(location) ? (
          <div styleName="empty-line-state">
            Draw points on the map to create a line. Double-click to finish.
          </div>
        ) : (
          <div styleName="line-location-container">
            <div>
              <div>{address}</div>
              <div styleName="subtext">{coordinatesSubtext}</div>
            </div>
            <IconButton onClick={handleClear}>
              <Icon>delete</Icon>
            </IconButton>
          </div>
        )}
      </div>
      <div styleName="map-container">
        <LineLocationTabMap
          onLoad={onLoad}
          map={map}
          clearDrawing={clearDrawing}
          setClearDrawing={setClearDrawing}
        />
      </div>
    </div>
  );
};

export default LineLocationTab;
