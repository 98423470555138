import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import client from '@atom/graph/client';
import {
  GET_WORK_ORDER,
  GET_WORK_ORDERS,
  WORK_ORDER_UPDATE,
} from '@atom/graph/work';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, IconButton, Menu, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { PolicyAction } from '@atom/types/policy';
import { Task } from '@atom/types/task';
import { TimeEntryWorkOrder } from '@atom/types/timeEntry';
import {
  WorkOrderDetailType,
  WorkOrdersConnection,
  WorkOrdersConnectionInput,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';
import { hasAccess } from '@atom/utilities/accessUtilities';
import {
  currentUserRole,
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  CLOSED_STATUS_ID,
  COMPLETED_STATUS_ID,
  getWorkOrderStatusColor,
  IN_REVIEW_STATUS_ID,
  workOrderStatusTypes,
} from '@atom/utilities/workOrderStatusUtilities';
import { getValidationsFromError } from '@atom/utilities/workValidationUtilities';

import ConfirmationModal from './ConfirmationModal';

import './workOrderStatusEdit.css';

const { MenuItem } = Menu;

const styles = {
  warningIconButton: {
    padding: '0',
  },
};

interface Props {
  workOrder: WorkOrderDetailType | TimeEntryWorkOrder;
  createdById: string;
  styleType: '' | 'table' | 'detail' | 'detail-pane' | 'dashboard-map-list';
  refetch: () => void;
}

const WorkOrderStatusEdit = ({
  workOrder,
  createdById,
  refetch,
  styleType,
}: Props) => {
  const userProfile = useUserProfile();

  const preferences = usePreferences();
  const reopenClosedWorkEnabled =
    preferences?.workOrders?.reopenClosedWorkEnabled;
  const completedDateEnabled = preferences?.workOrders?.completedDateEnabled;
  const reopenOwnClosedWork = preferences?.workOrders?.reopenOwnClosedWork;

  const { setWorkValidations } = useWorkValidations();

  const [statusId, setStatusId] = useState<string>(workOrder.statusId);
  const [completionDate, setCompletionDate] = useState<Date>(
    workOrder.completionDate ? new Date(workOrder.completionDate) : new Date(),
  );
  const [open, setOpen] = useState<boolean>(false);
  const [workWarning, setWorkWarning] = useState<boolean>(false);

  const [
    getFullWorkOrder,
    { data: fullWorkOrderData, loading: fullWorkOrderLoading },
  ] = useLazyQuery<{
    workOrder: WorkOrderDetailType;
  }>(GET_WORK_ORDER);

  const [updateWorkDetail, { loading: workUpdateLoading }] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE, {
    onCompleted: () => {
      setOpen(false);
      refetch();
    },
  });

  useEffect(() => {
    setStatusId(workOrder.statusId);
  }, [workOrder.statusId]);

  useEffect(() => {
    setCompletionDate(
      workOrder.completionDate
        ? new Date(workOrder.completionDate)
        : new Date(),
    );
  }, [workOrder.completionDate]);

  const fullWorkOrder = fullWorkOrderData?.workOrder;

  const setGWRRWorkWarning = async () => {
    const { data } = await client.query<
      { workOrders: WorkOrdersConnection },
      { input: WorkOrdersConnectionInput }
    >({
      query: GET_WORK_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          assetId: workOrder?.inventoryAssetId,
          statusIds: [0, 1, 2, 3, 4],
        },
      },
    });

    const totalCount = data?.workOrders?.totalCount;

    if (totalCount > 1) {
      setWorkWarning(true);
    }
  };

  useEffect(() => {
    // For work detail views, if work has a root asset and the client is GWRR, check
    // to see if the asset has any other "open" work and show error accordingly.
    if (
      styleType === 'detail' &&
      !isNilOrEmpty(workOrder?.inventoryAssetId) &&
      isCurrentClient([Client.GWRR])
    ) {
      setGWRRWorkWarning();
    }
  }, [workOrder, styleType]);

  const hasIncompleteTasks = useMemo(() => {
    return !isNilOrEmpty(workOrder.tasks)
      ? R.pathOr([], ['tasks'], workOrder).reduce(
          (acc: boolean, task: Task): boolean => {
            return acc ? !task.isCompleted : acc;
          },
          true,
        )
      : false;
  }, [workOrder]);

  const hasPendingChanges = useMemo(() => {
    if (!fullWorkOrder) {
      return false;
    }

    return R.values(fullWorkOrder.assets).some(
      (asset: any) => asset.hasPendingChanges,
    );
  }, [fullWorkOrder]);

  // User can close their own work if the current client is Hennepin
  // and work is created by logged in user.
  const canCloseOwnWork =
    isCurrentClient([Client.HENNEPIN]) && userProfile?.userId === createdById;

  const canCloseWork = canCloseOwnWork
    ? hasRolePermissions(ROLE_SETS.INSPECTOR)
    : hasRolePermissions(ROLE_SETS.MANAGER);

  const isWorkStatusDisabled = (
    currentStatus: string,
    status: string,
  ): boolean => {
    if (!canCloseWork && status === CLOSED_STATUS_ID) {
      return true;
    }

    if (
      currentStatus !== COMPLETED_STATUS_ID &&
      currentStatus !== CLOSED_STATUS_ID &&
      currentStatus !== IN_REVIEW_STATUS_ID &&
      status === IN_REVIEW_STATUS_ID
    ) {
      return true;
    }

    return false;
  };

  const dropdownStatusTypes = canCloseWork
    ? workOrderStatusTypes
    : workOrderStatusTypes.slice(0, Number(CLOSED_STATUS_ID));

  // If the tenant preference for reopening own closed work is true and the work
  // was created by the currently logged in user, then Inspectors and above can reopen it.
  const canOpenClosedWork =
    reopenOwnClosedWork &&
    reopenClosedWorkEnabled &&
    userProfile.userId === createdById
      ? hasRolePermissions(ROLE_SETS.INSPECTOR)
      : // admin can always reopen
        hasRolePermissions(ROLE_SETS.ADMIN) ||
        // everyone else has to have the tenant preference
        (reopenClosedWorkEnabled &&
          // if the work is NOT created from a work template, allow org admins
          ((!workOrder.workTemplateId &&
            hasRolePermissions(ROLE_SETS.ORG_ADMIN)) ||
            // work created from a work template can only be reopened by someone with
            // the correct RBAC policy assigned to them.
            hasAccess(
              PolicyAction.REOPEN_CLOSED_WORK,
              workOrder.workTemplateFolderActions,
            )));

  const disablePopoverOwnWork = doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const disablePopoverWork =
    (workOrder.statusId === CLOSED_STATUS_ID && !canOpenClosedWork) ||
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR) ||
    (currentUserRole === 'Inspector' && statusId === COMPLETED_STATUS_ID);

  // If the tenant preference for reopening own closed work is true and the work
  // was created by the currently logged in user, then Inspectors and above can reopen it.
  const disablePopover =
    reopenOwnClosedWork &&
    reopenClosedWorkEnabled &&
    userProfile.userId === createdById
      ? disablePopoverOwnWork
      : disablePopoverWork;

  const updateWorkOrder = async (
    statusIdValue: string,
    completionDateValue: Date,
  ) => {
    const isReopening = workOrder?.statusId === CLOSED_STATUS_ID;
    const sendCompletionDate =
      completionDateValue && completedDateEnabled && !isReopening;

    try {
      await updateWorkDetail({
        variables: {
          workOrder: {
            id: workOrder.id,
            ...(statusIdValue ? { statusId: statusIdValue } : {}),
            ...(sendCompletionDate
              ? {
                  completionDate: convertDateToMillisGMTMidday(
                    completionDateValue,
                  ),
                }
              : {}),
          },
        },
      });
    } catch (err) {
      if (err.networkError.statusCode === 422) {
        const taskValidations = getValidationsFromError(err);

        setWorkValidations({
          workOrderId: workOrder.id,
          taskValidations,
        });
      }

      const message =
        err.networkError.statusCode === 422
          ? 'Unable to change work status. Task required fields are missing.'
          : 'Something went wrong. Please try again or contact administrator.';

      Snackbar.error({ message });
      setOpen(false);
    }
  };

  const handleCompletionDateChange = (date?: Date) => {
    if (!date) {
      setCompletionDate(null);
    } else if (!Number.isNaN(date.valueOf())) {
      setCompletionDate(date);
    }
  };

  const updateStatus = async newStatus => {
    setStatusId(newStatus);

    const isCurrentStatusClosed = workOrder.statusId === CLOSED_STATUS_ID;
    const isNewStatusClosed = newStatus === CLOSED_STATUS_ID;
    const isNewStatusCompletedWithIncompleteTasks =
      newStatus === COMPLETED_STATUS_ID && hasIncompleteTasks;
    const isNewStatusCompletedWithDateOrInspectorSettings =
      newStatus === COMPLETED_STATUS_ID &&
      (completedDateEnabled || currentUserRole === 'Inspector');

    // If no specific case is meet, freely update work status.
    // Some cases here could be consolidated, however some require a specific order.
    switch (true) {
      case isCurrentStatusClosed: {
        return setOpen(true);
      }
      case isNewStatusClosed: {
        getFullWorkOrder({
          variables: {
            id: workOrder.id,
          },
        });

        return setOpen(true);
      }
      case isNewStatusCompletedWithIncompleteTasks:
      case isNewStatusCompletedWithDateOrInspectorSettings: {
        return setOpen(true);
      }
      default:
        return updateWorkOrder(newStatus, completionDate);
    }
  };

  const onClose = () => {
    setOpen(false);
    setStatusId(workOrder.statusId);
    setCompletionDate(
      workOrder.completionDate
        ? new Date(workOrder.completionDate)
        : new Date(),
    );
  };

  const loading = workUpdateLoading || fullWorkOrderLoading;

  return (
    <>
      <Menu
        icon={
          <WorkOrderStatusPill
            statusId={workOrder.statusId}
            className={styleType}
            data-cy="workOrderStatus"
          />
        }
        disabled={disablePopover}
        noIconButton
      >
        {dropdownStatusTypes.map(status => {
          const background = getWorkOrderStatusColor(status.value);
          const isDisabled = isWorkStatusDisabled(
            workOrder.statusId,
            status.value,
          );

          const onClick = isDisabled
            ? () => {}
            : () => updateStatus(status.value);

          return (
            <MenuItem
              key={status.value}
              onClick={onClick}
              disabled={isDisabled}
              selected={workOrder.statusId === status.value}
            >
              <div styleName="option-container">
                <div styleName="option-title">
                  <div
                    style={{
                      background,
                      opacity: isDisabled ? '50%' : '100%',
                    }}
                    styleName="status-indicator"
                  />
                  {status.label}
                </div>
                {status.value === CLOSED_STATUS_ID && workWarning && (
                  <IconButton
                    tooltip="Asset has other open work."
                    style={styles.warningIconButton}
                  >
                    <Icon color={colors.brand.orange}>warning</Icon>
                  </IconButton>
                )}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
      <ConfirmationModal
        open={open}
        onClose={onClose}
        workOrder={workOrder}
        statusId={statusId}
        hasPendingChanges={hasPendingChanges}
        hasIncompleteTasks={hasIncompleteTasks}
        loading={loading}
        updateWorkOrder={updateWorkOrder}
        completionDate={completionDate}
        handleCompletionDateChange={handleCompletionDateChange}
      />
    </>
  );
};

export default WorkOrderStatusEdit;
