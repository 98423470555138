import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Snackbar } from '@atom/components/common/mui/snackbar/Snackbar';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import { TASK_USERS_STATUS_UPDATE } from '@atom/graph/task';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Button, Icon, IconButton } from '@atom/mui';
import { getTaskUserStatusValues } from '@atom/selectors/taskSelectors';
import colors from '@atom/styles/colors';
import {
  Task,
  TaskUsersStatusUpdateInput,
  TaskUserStatus,
} from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import { WorkOrderDetailType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { getValidationsFromError } from '@atom/utilities/workValidationUtilities';

import './userTimeEntryModal.css';

const styles = {
  blueButton: {
    marginLeft: '0.5rem',
    color: colors.brand.blue,
  },
};

interface Props {
  user: UserDetail;
  leadAssigneeId: string;
  setLeadAssignee: (userId: string) => void;
  isClosed: boolean;
  loading: boolean;
  taskUserStatus: TaskUserStatus;
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  refetch: () => void;
}

const ModalTitle = ({
  user,
  leadAssigneeId,
  setLeadAssignee,
  isClosed,
  loading,
  taskUserStatus,
  workOrderDetail,
  task,
  refetch,
}: Props) => {
  const userProfile = useUserProfile();

  const { setWorkValidations } = useWorkValidations();

  const isCompleted = taskUserStatus === TaskUserStatus.COMPLETED;
  const isAssigned = taskUserStatus === TaskUserStatus.ASSIGNED;
  const isInProgress = taskUserStatus === TaskUserStatus.IN_PROGRESS;

  const [
    updateUsersTaskStatus,
    { loading: updateUsersTaskStatusLoading },
  ] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const toggleComplete = async () => {
    const status = isCompleted
      ? TaskUserStatus.IN_PROGRESS
      : TaskUserStatus.COMPLETED;

    try {
      await updateUsersTaskStatus({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            users: [
              {
                userId: user.id,
                status,
              },
            ],
          },
        },
      });
    } catch (err) {
      if (err.networkError.statusCode === 422) {
        const taskValidations = getValidationsFromError(err);

        setWorkValidations({
          workOrderId: workOrderDetail.id,
          taskValidations,
        });
      }

      const message =
        err.networkError.statusCode === 422
          ? 'Unable to mark complete. Task required fields are missing.'
          : 'Something went wrong. Please try again or contact administrator.';

      Snackbar.error({ message });
    }
  };

  const checkIn = async () => {
    await updateUsersTaskStatus({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          users: [
            {
              userId: user.id,
              status: TaskUserStatus.IN_PROGRESS,
            },
          ],
        },
      },
    });

    Snackbar.info({
      message: 'Checked In',
    });
  };

  const userName = loading ? '' : `${user?.firstName} ${user?.lastName}`;
  const isLeadAssignee = leadAssigneeId === user?.id;

  const taskUserStatusValues = getTaskUserStatusValues(taskUserStatus);
  const taskUserStatusColor = { backgroundColor: taskUserStatusValues.color };

  const completedColor = isCompleted ? colors.brand.green : colors.brand.blue;
  const completedIcon = isCompleted ? 'check_circle' : 'check_circle_outlined';
  const completedText = isCompleted ? 'Completed' : 'Complete';

  const canChangeUserStatus =
    (user?.id === userProfile.userId &&
      hasRolePermissions(ROLE_SETS.INSPECTOR)) ||
    (user?.id !== userProfile.userId && hasRolePermissions(ROLE_SETS.MANAGER));

  const showCheckInButton =
    (isNilOrEmpty(user) || isAssigned) && canChangeUserStatus;
  const showCompleteButton =
    (isInProgress || isCompleted) && canChangeUserStatus;

  const isCheckInDisabled =
    updateUsersTaskStatusLoading || workOrderDetail.isClosed;
  const isCompletedDisabled = updateUsersTaskStatusLoading;

  return (
    <div styleName="header-container">
      <div styleName="header-section">
        <UserThumbnail image={user?.photoUrl} alt={userName} />
        <div styleName="title-container">
          <div styleName="header-title">{userName}</div>
          <div styleName="header-status" style={taskUserStatusColor}>
            {taskUserStatusValues.text}
          </div>
          <div styleName="header-subtext">{user?.email}</div>
        </div>
      </div>
      <div styleName="header-section">
        {showCheckInButton && (
          <Button
            variant="outlined"
            style={styles.blueButton}
            startIcon={<Icon color={colors.brand.blue}>person_pin_circle</Icon>}
            disabled={isCheckInDisabled}
            onClick={checkIn}
          >
            Check In
          </Button>
        )}
        {showCompleteButton && (
          <Button
            variant="outlined"
            style={{ marginLeft: '0.5rem', color: completedColor }}
            startIcon={<Icon color={completedColor}>{completedIcon}</Icon>}
            disabled={isCompletedDisabled}
            onClick={toggleComplete}
          >
            {completedText}
          </Button>
        )}
        <IconButton
          onClick={() => setLeadAssignee(isLeadAssignee ? '' : user.id)}
          disabled={isClosed}
        >
          {isLeadAssignee ? (
            <Icon color={colors.brand.blue}>star</Icon>
          ) : (
            <Icon>star_border</Icon>
          )}
        </IconButton>
        <Link to={`/team/${user?.id}`}>
          <IconButton>
            <Icon>launch</Icon>
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

export default ModalTitle;
