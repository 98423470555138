import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import UserTimeEntryModal from '@atom/components/common/workOrderDetail/userTimeEntryModal/UserTimeEntryModal';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_USER_REMOVE } from '@atom/graph/task';
import { GET_TIME_ENTRIES_STATUS } from '@atom/graph/timeEntry';
import { Button, Icon, IconButton, Modal, Progress } from '@atom/mui';
import { getTaskUserStatusValues } from '@atom/selectors/taskSelectors';
import colors from '@atom/styles/colors';
import { Task, TaskUser, TaskUserRemoveInput } from '@atom/types/task';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { setWorkTimeDisplay } from '@atom/utilities/timeUtilities';

import './teamSection.css';

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
  removeButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

interface Props {
  user: TaskUser;
}

const TaskUserTile = ({ user }: Props) => {
  const { workOrderDetail, refetch, dispatch } = useContext(
    WorkOrderPreviewContext,
  );
  const { task } = useContext(TaskContext);

  const [open, setOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [taskUserRemove] = useMutation<
    { taskUserRemove: Task },
    { input: TaskUserRemoveInput }
  >(TASK_USER_REMOVE, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (removeModalOpen) {
      getTimeEntriesStatus({
        variables: {
          input: {
            userId: user.id,
            taskId: task.id,
            workOrderId: workOrderDetail.id,
            statuses: [TimeEntryStatus.approved],
          },
        },
      });
    }
  }, [removeModalOpen]);

  const removeUser = async () => {
    await taskUserRemove({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          userId: user.id,
        },
      },
    });

    setRemoveModalOpen(false);
    refetch();
  };

  const userName = `${user.firstName} ${user.lastName}`;

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.MANAGER);

  const hasApprovedTimeEntries =
    timeEntriesStatusData?.timeEntries?.totalCount > 0;

  const title = hasApprovedTimeEntries
    ? `Cannot Remove User`
    : 'Remove User From Task';

  const content = hasApprovedTimeEntries
    ? 'Team members with approved work time cannot be removed.'
    : 'Are you sure you want to remove this user from the task? This will also remove any equipment or material usage entries for this user.';

  const getFooter = () => {
    return hasApprovedTimeEntries ? (
      <Button onClick={() => setRemoveModalOpen(false)}>OK</Button>
    ) : (
      <div>
        <Button
          onClick={() => setRemoveModalOpen(false)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={timeEntriesStatusLoading}
          onClick={removeUser}
          style={styles.removeButton}
        >
          Remove
        </Button>
      </div>
    );
  };

  const showActualCost = hasRolePermissions(ROLE_SETS.MANAGER);

  return (
    <>
      <div styleName="user-container">
        <div styleName="user-content-container" onClick={() => setOpen(true)}>
          <UserThumbnail
            taskUserStatus={user.status}
            image={user.photoUrl}
            alt={userName}
          />
          <div styleName="user-details">
            <div styleName="name">{userName}</div>
            <div styleName="email">
              <span>{getTaskUserStatusValues(user.status).text}</span>
              <span styleName="spacer">&#183;</span>
              <span>{setWorkTimeDisplay(user.workTime)}</span>
              {showActualCost && (
                <>
                  <span styleName="spacer">&#183;</span>
                  <span>{numberToLocaleString(user.actualCost)}</span>
                </>
              )}
            </div>
          </div>
        </div>
        {!isEditDisabled && (
          <IconButton
            onClick={() => setRemoveModalOpen(true)}
            tooltip="Remove"
            edge="end"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      </div>
      <UserTimeEntryModal
        open={open}
        onClose={() => setOpen(false)}
        userId={user.id}
        workTime={user.workTime}
        taskUserStatus={user.status}
        workOrderDetail={workOrderDetail}
        task={task}
        refetch={refetch}
        dispatch={dispatch}
        WorkOrderActionTypes={WorkOrderActionTypes}
      />
      <Modal
        open={removeModalOpen}
        title={timeEntriesStatusLoading ? '' : title}
        footer={timeEntriesStatusLoading ? '' : getFooter()}
      >
        {timeEntriesStatusLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          content
        )}
      </Modal>
    </>
  );
};

export default TaskUserTile;
