import React, { useMemo } from 'react';
import * as R from 'ramda';

// @ts-ignore
import assetEmptyIcon from '@atom/components/common/svgIcons/assetEmptyIcon.svg';
import { Button, Icon, IconButton, Progress } from '@atom/mui';
import { AssetConnectionItem } from '@atom/types/inventory';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { pluralize } from '@atom/utilities/stringUtilities';

import TaskAssetRow from '../TaskAssetRow';
import { AddAssetError } from '../taskLocationUtilities';

import AldotAssetError from './AldotAssetError';

import './taskAssetModal.css';

const styles = {
  icon: {
    height: '20px',
    width: '20px',
    fontSize: '20px',
  },
  tooltipIconButton: {
    padding: '0',
  },
};

interface Props {
  assets: AssetConnectionItem[];
  cartAssetIds: string[];
  assetsLoading: boolean;
  removeAsset: (id: string) => void;
  clearAssets: () => void;
  setHoverId: (id: string) => void;
  selectedAssetId?: string;
  setSelectedAssetId?: (id: string) => void;
  assetErrorType: AddAssetError;
}

const TaskAssetSidebar = ({
  assets = [],
  cartAssetIds = [],
  assetsLoading,
  removeAsset,
  clearAssets,
  setHoverId,
  selectedAssetId,
  setSelectedAssetId,
  assetErrorType,
}: Props) => {
  const assetCount = assets.length;
  const headerText = `${assetCount} ${pluralize(assetCount, 'Asset')} Selected`;
  const showAssetError = assetErrorType !== AddAssetError.NONE;

  const tooltipText =
    'Management Unit, County, and Road Class for locations and assets must remain the same on the task. To modify, please delete all assets and locations and start over.';

  const orderedAssets = useMemo(() => {
    return cartAssetIds.map(id => R.find(R.propEq('id', id), assets));
  }, [assets]);

  const showAssetList = orderedAssets.length > 0;

  const assetErrorComponent = useMemo(() => {
    // Error components will be based off client specific enums
    // Currently only ALDOT tenants have add asset validation.

    switch (assetErrorType) {
      case AddAssetError.ALDOT_CART:
      case AddAssetError.ALDOT_CROSS:
        return <AldotAssetError assetErrorType={assetErrorType} />;
      default:
        return <div />;
    }
  }, [assetErrorType]);

  const showAldotTooltip = isCurrentClient([Client.ALDOT]);

  return (
    <div styleName="sidebar-container">
      <div styleName="section-header">
        <div styleName="title-container">
          <div styleName="title">{headerText}</div>
          {showAldotTooltip && (
            <IconButton tooltip={tooltipText} style={styles.tooltipIconButton}>
              <Icon style={styles.icon}>help</Icon>
            </IconButton>
          )}
        </div>
        <div styleName="header-action-buttons">
          {assetsLoading && <Progress size={25} thickness={2} />}
          <Button
            color="primary"
            onClick={clearAssets}
            data-cy="addAssetModalClearAllButton"
          >
            Clear All
          </Button>
        </div>
      </div>
      <div styleName="asset-list">
        {showAssetList ? (
          <>
            {showAssetError && assetErrorComponent}
            {orderedAssets.map(asset => (
              <TaskAssetRow
                key={asset?.id}
                asset={asset}
                removeAsset={removeAsset}
                setHoverId={setHoverId}
                selectedAssetId={selectedAssetId}
                setSelectedAssetId={setSelectedAssetId}
              />
            ))}
          </>
        ) : (
          <div styleName="empty-container">
            <div styleName="empty-content">
              <img src={assetEmptyIcon} />
              <div styleName="empty-text">
                Find assets by searching the map or by asset name in folders,
                then select them by clicking.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskAssetSidebar;
