import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import CreateAssetModalContext, {
  ModalStep,
} from '@atom/components/common/createAssetModal/CreateAssetModalContext';
import { CREATE_ASSET } from '@atom/graph/asset';
import { GET_SCHEMA } from '@atom/graph/schema';
import { Location, useCurrentLocation } from '@atom/hooks/useCurrentLocation';
import { Button, Icon, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetCreateAttributesInput,
  AssetCreateInput,
  AssetDetail,
  InventoryCategoryTree,
} from '@atom/types/inventory';
import { Schema } from '@atom/types/schema';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AttributesTab from './attributesTab/AttributesTab';
import LineLocationTab from './lineLocationTab/LineLocationTab';
import PointLocationTab from './pointLocationTab/PointLocationTab';
import CostSettingsTab from './CostSettingsTab';
import {
  cleanAttributes,
  getErrorAttributeIds,
  getModalStepsArray,
  isCurrentStepDisabled,
} from './createAssetUtilities';
import FolderSelectionTab from './FolderSelectionTab';
import NameTab from './NameTab';
import SchemaSelectionTab from './SchemaSelectionTab';
import StepperSidebar from './StepperSidebar';

import './createAssetModal.css';

const styles = {
  modalContent: {
    padding: 0,
  },
  back: {
    marginRight: '1rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  /**
   * If a leaf node category is passed in, it will be used to populate the schema and category steps
   */
  initialCategory?: InventoryCategoryTree;
}

const CreateAssetModal = ({ open, onClose, initialCategory }: Props) => {
  const { pointLocation } = useCurrentLocation();

  const [activeStep, setActiveStep] = useState<ModalStep>(ModalStep.TYPE);
  const [collapsedGroups, setCollapsedGroups] = useState<Set<String>>(
    new Set([]),
  );
  const [openCategories, setOpenCategories] = useState<Set<string>>(null);

  const [schema, setSchema] = useState<Schema>();
  const [category, setCategory] = useState<InventoryCategoryTree>();
  const [location, setLocation] = useState<Location>();
  const [address, setAddress] = useState<string>('');
  const [locationFromSearch, setLocationFromSearch] = useState<boolean>(false);
  const [rate, setRate] = useState<number>();
  const [unit, setUnit] = useState<string>('');
  const [isStockBased, setIsStockBased] = useState<boolean>(false);
  const [isStartEndReading, setIsStartEndReading] = useState<boolean>(false);
  const [isStartEndCalculated, setIsStartEndCalculated] = useState<boolean>(
    false,
  );
  const [quantityOnHand, setQuantityOnHand] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [attributes, setAttributes] = useState<AssetCreateAttributesInput>();
  const [errorAttributeIds, setErrorAttributeIds] = useState<Set<string>>(
    new Set([]),
  );

  const [createSchema] = useMutation<
    { assetCreate: AssetDetail },
    { input: AssetCreateInput }
  >(CREATE_ASSET);

  const [getSchema, { loading }] = useLazyQuery<
    { schema: Schema },
    { id: string }
  >(GET_SCHEMA, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setSchema(data?.schema || null);
    },
  });

  useEffect(() => {
    if (open) {
      if (initialCategory?.schemaId) {
        getSchema({ variables: { id: initialCategory?.schemaId } });
      }

      setCategory(initialCategory);
    }
  }, [open, initialCategory]);

  const resetValues = () => {
    setActiveStep(ModalStep.TYPE);
    setCollapsedGroups(new Set([]));
    setOpenCategories(null);
    setErrorAttributeIds(new Set([]));
    setRate(null);
    setUnit('');
    setIsStockBased(false);
    setIsStartEndReading(false);
    setIsStartEndCalculated(false);
    setQuantityOnHand(0);
    setName('');
    setAttributes(null);
    setLocation(null);
    setAddress('');
    setLocationFromSearch(false);
  };

  const onCloseModal = () => {
    setSchema(null);
    setCategory(null);
    resetValues();
    onClose();
  };

  useEffect(() => {
    // Only reset the currently selected category if it does not match the new schema
    if (
      category?.schemaId !== schema?.id &&
      !isNilOrEmpty(category?.schemaId)
    ) {
      setCategory(null);
    }

    resetValues();
  }, [schema]);

  useEffect(() => {
    // When schema changes, set the initial location
    if (!schema?.isMaterial) {
      setLocation(schema?.locationType === 'LineString' ? null : pointLocation);
    }
  }, [schema]);

  const STEPS_ARRAY = getModalStepsArray(schema);

  const isConfirmDisabled = isCurrentStepDisabled({
    STEPS_ARRAY,
    schema,
    category,
    activeStep,
    location,
    rate,
    unit,
    isStockBased,
    quantityOnHand,
    name,
    errorAttributeIds,
  });

  const confirm = async () => {
    const payload = {
      name,
      externalId: name,
      schemaId: schema.id,
      categoryId: category.id,
      attributes: cleanAttributes(attributes),
      ...(!schema?.isMaterial && {
        location,
      }),
      ...(schema.isMaterial && {
        budget: {
          rate,
          unit,
          quantityOnHand,
          isStockBased,
          isStartEndReading,
          isStartEndCalculated,
        },
      }),
    };

    try {
      const { data } = await createSchema({
        variables: {
          input: payload,
        },
      });

      history.push(`/inventory/${data?.assetCreate?.id}`);
    } catch (err) {
      const message =
        err.networkError.statusCode === 422
          ? 'Inventory Type is not published.'
          : 'An error occurred. Please try again.';
      Snackbar.error({ message });
    } finally {
      onCloseModal();
    }
  };

  const getContent = (): React.ReactNode => {
    const content = {
      [ModalStep.TYPE]: <SchemaSelectionTab />,
      [ModalStep.FOLDER]: <FolderSelectionTab />,
      [ModalStep.LOCATION]:
        schema?.locationType === 'LineString' ? (
          <LineLocationTab />
        ) : (
          <PointLocationTab />
        ),
      [ModalStep.COST]: <CostSettingsTab />,
      [ModalStep.ATTRIBUTES]: <AttributesTab />,
      [ModalStep.NAME]: <NameTab />,
    };

    return content[activeStep];
  };

  const handleStep = (value: number) => {
    const currentStepIndex = R.findIndex(R.propEq('value', activeStep))(
      STEPS_ARRAY,
    );

    const currentStep = STEPS_ARRAY[currentStepIndex];

    if (currentStep.value === ModalStep.ATTRIBUTES && value > 0) {
      const errorIds = getErrorAttributeIds(schema, attributes);

      if (!isNilOrEmpty(errorIds)) {
        setErrorAttributeIds(new Set(errorIds));
        return;
      }
    }

    const newStep = STEPS_ARRAY[currentStepIndex + value];

    setActiveStep(newStep.value);
  };

  const getFooter = () => {
    const showCreateButton =
      R.findIndex(R.propEq('value', activeStep), STEPS_ARRAY) ===
      STEPS_ARRAY.length - 1;
    const showBackButton = activeStep !== ModalStep.TYPE;

    const rightArrowColor = isConfirmDisabled
      ? colors.neutral.gray
      : colors.neutral.white;

    return (
      <div styleName="footer">
        {showBackButton ? (
          <Button onClick={() => handleStep(-1)}>
            <Icon>chevron_left</Icon>Back
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button
            onClick={onCloseModal}
            style={{ marginRight: '0.5rem' }}
            data-cy="createAssetModalCancelButton"
          >
            Cancel
          </Button>
          {showCreateButton ? (
            <Button
              color="primary"
              variant="contained"
              onClick={confirm}
              disabled={isConfirmDisabled}
              data-cy="createAssetModalCreateButton"
            >
              Create
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleStep(1)}
              disabled={isConfirmDisabled}
              data-cy="createAssetModalNextButton"
            >
              Next<Icon color={rightArrowColor}>chevron_right</Icon>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const modalSize =
    activeStep === ModalStep.ATTRIBUTES || activeStep === ModalStep.LOCATION
      ? 'xl'
      : 'lg';
  const content = getContent();

  return (
    <CreateAssetModalContext.Provider
      value={{
        activeStep,
        setActiveStep,
        STEPS_ARRAY,
        collapsedGroups,
        setCollapsedGroups,
        openCategories,
        setOpenCategories,
        schema,
        setSchema,
        category,
        setCategory,
        location,
        setLocation,
        address,
        setAddress,
        rate,
        setRate,
        unit,
        setUnit,
        isStockBased,
        setIsStockBased,
        isStartEndReading,
        setIsStartEndReading,
        isStartEndCalculated,
        setIsStartEndCalculated,
        quantityOnHand,
        setQuantityOnHand,
        name,
        setName,
        attributes,
        setAttributes,
        errorAttributeIds,
        setErrorAttributeIds,
        locationFromSearch,
        setLocationFromSearch,
      }}
    >
      <Modal
        title="Create Inventory"
        footer={getFooter()}
        open={open}
        onCancel={() => onCloseModal()}
        contentStyle={styles.modalContent}
        width={modalSize}
        loading={loading}
      >
        <div styleName="modal-content">
          <StepperSidebar />
          {content}
        </div>
      </Modal>
    </CreateAssetModalContext.Provider>
  );
};

export default CreateAssetModal;
