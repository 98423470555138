import React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { GrantType, PolicyAction, PolicyResource } from '@atom/types/policy';
import {
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';

import { capitalSnakeCaseToTitleCase } from './stringUtilities';

type PolicyResourceOption = {
  value: PolicyResource;
  label: string;
  icon?: React.ReactNode;
  actions: PolicyAction[];
  grantTypes?: GrantType[];
  hide?: boolean;
  subSourceOnly?: boolean;
};

const policyActionCustomLabels: { [key in PolicyAction]?: string } = {
  [PolicyAction.UPDATE]: 'Edit',
  [PolicyAction.BULK_UPDATE]: 'Bulk Edit',
  // [PolicyAction.UPDATE_RELATIONSHIP]: 'Edit Linked Inventory',
  [PolicyAction.CREATE_MEDIA]: 'Upload Media',
  [PolicyAction.UPDATE_MEDIA]: 'Edit Media',
};

export const getPolicyActionLabel = (action: PolicyAction) => {
  return (
    policyActionCustomLabels[action] || capitalSnakeCaseToTitleCase(action)
  );
};

const styles = {
  inventoryTypeIcon: {
    background: colors.brand.purple,
    color: colors.neutral.white,
    borderRadius: '4px',
    fontSize: '16px',
    padding: '2px',
    margin: '0 2px',
  },
  budgetPortalIcon: {
    background: colors.brand.turquoise,
    color: colors.neutral.white,
    borderRadius: '10px',
    fontSize: '20px',
    paddingLeft: '1.5px',
    paddingRight: '0.5px',
    marginRight: '2px',
  },
};

export const POLICY_RESOURCE_OPTIONS: {
  [key in PolicyResource]: PolicyResourceOption;
} = {
  [PolicyResource.INVENTORY_ITEM]: {
    value: PolicyResource.INVENTORY_ITEM,
    label: 'Inventory Item',
    icon: <Icon color={colors.brand.purple}>category</Icon>,
    actions: [
      PolicyAction.ALL,
      PolicyAction.READ,
      PolicyAction.UPDATE,
      PolicyAction.BULK_UPDATE,
      PolicyAction.RENAME,
      PolicyAction.UPDATE_LOCATION,
      // PolicyAction.UPDATE_RELATIONSHIP,
      PolicyAction.MANAGE_INVENTORY_CHANGES,
      PolicyAction.CREATE_MEDIA,
      PolicyAction.UPDATE_MEDIA,
      PolicyAction.DELETE_MEDIA,
    ],
    grantTypes: [
      GrantType.INVENTORY_TYPE,
      GrantType.INVENTORY_FOLDER,
      GrantType.INVENTORY_ITEM,
    ],
  },
  [PolicyResource.INVENTORY_TYPE]: {
    value: PolicyResource.INVENTORY_TYPE,
    label: 'Inventory Type',
    icon: <Icon style={styles.inventoryTypeIcon}>category</Icon>,
    actions: [PolicyAction.ALL, PolicyAction.READ],
    grantTypes: [GrantType.INVENTORY_TYPE],
  },
  [PolicyResource.INVENTORY_FOLDER]: {
    value: PolicyResource.INVENTORY_FOLDER,
    label: 'Inventory Folder',
    icon: <Icon color={colors.brand.purple}>folder</Icon>,
    actions: [PolicyAction.ALL, PolicyAction.READ],
    grantTypes: [GrantType.INVENTORY_FOLDER],
  },
  [PolicyResource.WORK]: {
    value: PolicyResource.WORK,
    label: 'Work',
    icon: <Icon color={colors.brand.blue}>work</Icon>,
    actions: [
      PolicyAction.ALL,
      PolicyAction.CREATE,
      PolicyAction.BULK_CREATE,
      PolicyAction.REOPEN_CLOSED_WORK,
    ],
    grantTypes: [GrantType.WORK_TYPE_FOLDER],
  },
  [PolicyResource.WORK_TYPE_FOLDER]: {
    value: PolicyResource.WORK_TYPE_FOLDER,
    label: 'Work Template Folder',
    icon: <Icon color={colors.brand.blue}>folder</Icon>,
    actions: [PolicyAction.ALL, PolicyAction.READ],
    grantTypes: [GrantType.WORK_TYPE_FOLDER],
  },
  ...(isCurrentTenant([Tenant.DEV_SADA]) && {
    [PolicyResource.WORK_LIST]: {
      value: PolicyResource.WORK_LIST,
      label: 'Work List',
      icon: <Icon color={colors.brand.blue}>work</Icon>,
      actions: [PolicyAction.MANAGE_COLUMN_DISPLAY],
      grantTypes: [GrantType.WORK_LIST],
    },
  }),
  [PolicyResource.ANALYTICS_FOLDER]: {
    value: PolicyResource.ANALYTICS_FOLDER,
    label: 'Analytics Folder',
    icon: <Icon color={colors.brand.green}>assessment</Icon>,
    actions: [PolicyAction.READ],
    grantTypes: [GrantType.ANALYTICS_FOLDER],
  },
  [PolicyResource.BUDGET]: {
    value: PolicyResource.BUDGET,
    label: 'User Budget',
    icon: <Icon color={colors.brand.red}>group</Icon>,
    actions: [PolicyAction.READ],
    grantTypes: [GrantType.USER],
  },
  [PolicyResource.BUDGETING]: {
    value: PolicyResource.BUDGETING,
    label: 'Budget',
    icon: (
      <Icon color={colors.neutral.white} style={styles.budgetPortalIcon}>
        attach_money
      </Icon>
    ),
    actions: [],
    grantTypes: [
      GrantType.BUDGETING_BUDGET,
      GrantType.BUDGETING_TEMPLATE,
      GrantType.BUDGETING_UNIT,
      GrantType.BUDGETING_APPROVAL,
    ],
  },
  [PolicyResource.BUDGETING_BUDGET]: {
    subSourceOnly: true,
    value: PolicyResource.BUDGETING_BUDGET,
    label: 'Budget',
    actions: [PolicyAction.READ],
  },
  [PolicyResource.BUDGETING_TEMPLATE]: {
    subSourceOnly: true,
    value: PolicyResource.BUDGETING_TEMPLATE,
    label: 'Budget Template',
    actions: [PolicyAction.READ],
  },
  [PolicyResource.BUDGETING_APPROVAL]: {
    subSourceOnly: true,
    value: PolicyResource.BUDGETING_APPROVAL,
    label: 'Budget Approval',
    actions: [
      PolicyAction.ALL,
      PolicyAction.APPROVE,
      PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED,
      PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED,
      PolicyAction.REVERT_TO_SUBMITTED_FROM_APPROVED,
      PolicyAction.SUBMIT_DRAFT,
    ],
  },
  [PolicyResource.BUDGETING_UNIT]: {
    subSourceOnly: true,
    value: PolicyResource.BUDGETING_UNIT,
    label: 'Budget Unit',
    actions: [
      PolicyAction.ALL,
      PolicyAction.READ,
      PolicyAction.EDIT_BUDGET_ITEM,
    ],
  },
};
