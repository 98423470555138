import React, { useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import TaskHeaderOptions from '@atom/components/common/workOrderDetail/taskOptions/TaskHeaderOptions';
import WorkOrderPreviewContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, IconButton, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Task, TaskUpdateInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import { removeFromSet } from '@atom/utilities/setUtilities';
import { getValidationsFromError } from '@atom/utilities/workValidationUtilities';

import './collapsedTask.css';

interface Props {
  task: Task;
}

const CollapsedTaskHeader = ({ task }: Props) => {
  const {
    collapsedTasks,
    setCollapsedTasks,
    workOrderDetail,
    refetch,
    dispatch,
  } = useContext(WorkOrderPreviewContext);

  const { workValidations, setWorkValidations } = useWorkValidations();

  const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);

  const [taskUpdate, { loading: taskUpdateLoading }] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  const expandSection = () => {
    setCollapsedTasks(removeFromSet(collapsedTasks, task.id));
  };

  const handleCompleteToggle = async () => {
    try {
      const newCompleteValue = !task.isCompleted;
      const completeText = newCompleteValue ? 'Complete' : 'Incomplete';

      Snackbar.info({ message: `Marking ${task.name} ${completeText}...` });

      await taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            isCompleted: newCompleteValue,
          },
        },
      });

      Snackbar.info({ message: `Marked ${task.name} ${completeText}.` });

      refetch();
    } catch (err) {
      if (err.networkError.statusCode === 422) {
        const taskValidations = getValidationsFromError(err);

        setWorkValidations({
          workOrderId: workOrderDetail.id,
          taskValidations,
        });
      }

      const message =
        err.networkError.statusCode === 422
          ? 'Unable to mark complete. Task required fields are missing.'
          : 'Something went wrong. Please try again or contact administrator.';

      Snackbar.error({ message });
    } finally {
      setCompleteModalOpen(false);
    }
  };

  const completeIcon = task.isCompleted
    ? 'check_circle'
    : 'check_circle_outline';
  const completeColor = task.isCompleted
    ? colors.brand.green
    : colors.neutral.silver;
  const tooltipText = task.isCompleted ? 'Mark Incomplete' : 'Mark Complete';

  const handleCompleteClick = () => {
    return task.isCompleted
      ? handleCompleteToggle()
      : setCompleteModalOpen(true);
  };

  // TODO: [AM-7948]: Move to RBAC
  const disableTaskCompleteButton =
    doesNotHaveRolePermissions(
      isCurrentTenant([Tenant.GWRR]) ? ROLE_SETS.MANAGER : ROLE_SETS.INSPECTOR,
    ) || workOrderDetail.isClosed;

  const hasPendingChanges = useMemo(() => {
    return task.assetIds.some(
      id => workOrderDetail.assets[id]?.hasPendingChanges,
    );
  }, [task]);

  const isTaskInvalid = R.has(task.id)(workValidations?.taskValidations);

  return (
    <>
      <div styleName="header-container">
        <div styleName="left-header-section">
          {isTaskInvalid ? (
            <Icon color={colors.brand.red}>error_outline</Icon>
          ) : (
            <div styleName="change-indicator-container">
              <IconButton
                size="small"
                onClick={handleCompleteClick}
                disabled={disableTaskCompleteButton}
                tooltip={tooltipText}
                edge="end"
              >
                <Icon color={completeColor}>{completeIcon}</Icon>
              </IconButton>
              {hasPendingChanges && <div styleName="change-indicator" />}
            </div>
          )}
          <div styleName="task-title">{task.name}</div>
        </div>
        <div styleName="right-header-section">
          <TaskHeaderOptions
            workOrderDetail={workOrderDetail}
            task={task}
            refetch={refetch}
            WorkOrderActionTypes={WorkOrderActionTypes}
            dispatch={dispatch}
            dataCyLabel="previewTaskAdditionalActions"
          />
          <IconButton onClick={expandSection} edge="end">
            <Icon>expand_more</Icon>
          </IconButton>
        </div>
      </div>
      <Modal
        open={completeModalOpen}
        loading={taskUpdateLoading}
        confirmButtonText="Complete"
        onCancel={() => setCompleteModalOpen(false)}
        onConfirm={handleCompleteToggle}
        title="Complete Task?"
      >
        Not everyone completed the task. This action will complete the task for
        everyone on the task. Are you sure?
      </Modal>
    </>
  );
};

export default CollapsedTaskHeader;
