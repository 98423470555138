import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';

// @ts-ignore
import dashboardImage from '@atom/components/common/svgIcons/dashboardPortal.svg';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Portal } from '@atom/types/portals';
import history from '@atom/utilities/history';
import { getPortals } from '@atom/utilities/portalUtilities';

import './navigation.css';

const styles = {
  iconStyle: {
    padding: '0 0.5rem',
    color: colors.neutral.gray,
  },
  dashboardIcon: {
    marginRight: '1rem',
    width: '2.5rem',
  },
};

interface Props {
  pathname: string;
}

const useStyles = makeStyles({
  paper: {
    marginTop: '10px',
    width: '224px',
  },
});

const PortalMenu = ({ pathname }: Props) => {
  const classes = useStyles();

  const preferences = usePreferences();
  const landingPortal = getPortals(preferences)[preferences.landingPortal];

  const anchor = useRef();

  const [open, setOpen] = useState<boolean>(false);

  const portals = useMemo(() => {
    return getPortals(preferences);
  }, [getPortals, preferences]);

  const currentPortal = useMemo(() => {
    const path = pathname.split('/')[1];

    switch (path) {
      case 'dashboard':
        return portals[Portal.DASHBOARD];
      case 'team':
        return portals[Portal.TEAM];
      case 'inventory':
        return portals[Portal.INVENTORY];
      case 'workOrders':
      case 'workExports':
      case 'form-repository':
      case 'work-template-repository':
      case 'form-instance':
      case 'form-builder':
      case 'work-templates':
        return portals[Portal.WORK];
      case 'analytics':
        return portals[Portal.ANALYTICS];
      case 'schedule':
        return portals[Portal.SCHEDULE];
      // admin routes can belong to different portals
      case 'admin': {
        const adminPath = pathname.split('/')[2];

        switch (adminPath) {
          case 'user-types':
            return portals[Portal.TEAM];
          case 'inventoryTypes':
            return portals[Portal.INVENTORY];
          default:
            return landingPortal;
        }
      }
      case 'budget':
      case 'budgets':
        return portals[Portal.BUDGETING];
      case 'map':
        return portals[Portal.MAP];
      default:
        return landingPortal;
    }
  }, [pathname, portals]);

  const handlePortalClick = (event: React.MouseEvent, path: string) => {
    event.preventDefault();
    setOpen(false);
    history.push(path);
  };

  const portalOrder = [
    Portal.INVENTORY,
    Portal.TEAM,
    Portal.WORK,
    Portal.ANALYTICS,
    Portal.SCHEDULE,
    Portal.MAP,
    Portal.BUDGETING,
  ];

  const enabledPortals = portalOrder.filter(
    // @ts-ignore
    portalKey => !getPortals(preferences)[portalKey]?.disabled,
  );

  const dashboardPortal = getPortals(preferences)[Portal.DASHBOARD];

  return (
    <div styleName="portal-wrapper" ref={anchor}>
      <div styleName="portal-container">
        <span styleName="left-divider" />
        <a
          href={currentPortal.urlPath}
          onClick={event => handlePortalClick(event, currentPortal.urlPath)}
        >
          <div styleName="portal-icon header">
            <img src={currentPortal.image} alt={currentPortal.description} />
          </div>
        </a>
        <Icon
          onClick={() => setOpen(true)}
          style={styles.iconStyle}
          className="material-icons"
        >
          apps
        </Icon>
        <span styleName="right-divider" />
      </div>
      <Popover
        open={open}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setOpen(false)}
        classes={classes}
      >
        <div styleName="dashboard-menu">
          <a
            styleName="dashboard-container"
            href={dashboardPortal.urlPath}
            onClick={event => handlePortalClick(event, dashboardPortal.urlPath)}
            data-cy={dashboardPortal.dataCy}
          >
            <Icon style={styles.dashboardIcon}>
              <img src={dashboardImage} alt="Dashboard" styleName="icon" />
            </Icon>
            <div styleName="dashboard-text">Dashboard</div>
          </a>
          <div styleName="portals-container">
            {enabledPortals.map(portalKey => {
              const { urlPath, description, image, dataCy } = getPortals(
                preferences,
              )[portalKey];

              return (
                <a
                  styleName="portal"
                  onClick={event => handlePortalClick(event, urlPath)}
                  href={urlPath}
                  key={portalKey}
                  data-cy={dataCy}
                >
                  <div styleName="portal-icon">
                    <img src={image} alt={description} />
                  </div>
                  <div styleName="portal-text">{description}</div>
                </a>
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default PortalMenu;
