import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import TaskHeaderOptions from '@atom/components/common/workOrderDetail/taskOptions/TaskHeaderOptions';
import UserStatusControls from '@atom/components/common/workOrderDetail/userStatusControls/UserStatusControls';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, IconButton, Modal, Snackbar, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Task, TaskUpdateInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import { addToSet } from '@atom/utilities/setUtilities';
import { getValidationsFromError } from '@atom/utilities/workValidationUtilities';

import './taskView.css';

const TaskHeader = () => {
  const {
    collapsedTasks,
    setCollapsedTasks,
    workOrderDetail,
    refetch,
    loading: workOrderLoading,
    dispatch,
  } = useContext(WorkOrderPreviewContext);
  const { task } = useContext(TaskContext);

  const { workValidations, setWorkValidations } = useWorkValidations();

  const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);
  const [renameOpen, setRenameOpen] = useState<boolean>(false);
  const [originalName, setOriginalName] = useState<string>(task?.originalName);

  const [taskUpdate, { loading: taskUpdateLoading }] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  useEffect(() => {
    setOriginalName(task?.originalName);
  }, [task, task?.originalName]);

  const collapseSection = () => {
    setCollapsedTasks(addToSet(collapsedTasks, task.id));
  };

  const handleCompleteToggle = async () => {
    try {
      const newCompleteValue = !task.isCompleted;
      const completeText = newCompleteValue ? 'Complete' : 'Incomplete';

      Snackbar.info({ message: `Marking ${task.name} ${completeText}...` });

      await taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            isCompleted: newCompleteValue,
          },
        },
      });

      Snackbar.info({ message: `Marked ${task.name} ${completeText}.` });

      refetch();
    } catch (err) {
      if (err.networkError.statusCode === 422) {
        const taskValidations = getValidationsFromError(err);

        setWorkValidations({
          workOrderId: workOrderDetail.id,
          taskValidations,
        });
      }

      const message =
        err.networkError.statusCode === 422
          ? 'Unable to mark complete. Task required fields are missing.'
          : 'Something went wrong. Please try again or contact administrator.';

      Snackbar.error({ message });
    } finally {
      setCompleteModalOpen(false);
    }
  };

  const completeIcon = task.isCompleted
    ? 'check_circle'
    : 'check_circle_outline';
  const completeColor = task.isCompleted
    ? colors.brand.green
    : colors.neutral.silver;
  const tooltipText = task.isCompleted ? 'Mark Incomplete' : 'Mark Complete';

  const handleCompleteClick = () => {
    return task.isCompleted
      ? handleCompleteToggle()
      : setCompleteModalOpen(true);
  };

  const onRename = async () => {
    const res = await taskUpdate({
      variables: {
        input: {
          id: task?.id,
          workOrderId: workOrderDetail?.id,
          name: originalName,
        },
      },
    });

    const name = res?.data?.taskUpdate?.name;
    const newOriginalName = res?.data?.taskUpdate?.originalName;

    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task?.id,
        property: 'name',
        value: name,
      },
    });

    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task?.id,
        property: 'originalName',
        value: newOriginalName,
      },
    });

    setRenameOpen(false);
  };

  // TODO: [AM-7948]: Move to RBAC
  const disableTaskCompleteButton =
    doesNotHaveRolePermissions(
      isCurrentTenant([Tenant.GWRR]) ? ROLE_SETS.MANAGER : ROLE_SETS.INSPECTOR,
    ) || workOrderDetail.isClosed;

  const hasPendingChanges = useMemo(() => {
    return task.assetIds.some(
      id => workOrderDetail.assets[id]?.hasPendingChanges,
    );
  }, [task]);

  const isTaskInvalid = R.has(task.id)(workValidations?.taskValidations);

  // Specific rename permission for ALDOT related tenants
  const aldotRenamePermissions =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
    !task?.taskTemplateId &&
    !workOrderDetail.isClosed;
  const basicRenamePermissions =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && !workOrderDetail.isClosed;

  const canRenameTask = isCurrentClient([Client.ALDOT])
    ? aldotRenamePermissions
    : basicRenamePermissions;

  const taskNameStyles = canRenameTask
    ? 'task-header-title-hover'
    : 'task-header-title';

  const onNameClick = canRenameTask ? () => setRenameOpen(true) : () => {};

  return (
    <>
      <div styleName="header-container">
        <div styleName="left-header-section">
          {isTaskInvalid ? (
            <Icon color={colors.brand.red}>error_outline</Icon>
          ) : (
            <div styleName="change-indicator-container">
              <IconButton
                size="small"
                onClick={handleCompleteClick}
                disabled={disableTaskCompleteButton}
                tooltip={tooltipText}
                edge="end"
              >
                <Icon color={completeColor}>{completeIcon}</Icon>
              </IconButton>
              {hasPendingChanges && <div styleName="change-indicator" />}
            </div>
          )}
          <div styleName={taskNameStyles} onClick={onNameClick}>
            {task?.name}
          </div>
        </div>
        <div styleName="right-header-section">
          <UserStatusControls
            workOrderDetail={workOrderDetail}
            workOrderLoading={workOrderLoading}
            task={task}
            refetch={refetch}
          />
          <TaskHeaderOptions
            workOrderDetail={workOrderDetail}
            task={task}
            refetch={refetch}
            WorkOrderActionTypes={WorkOrderActionTypes}
            dispatch={dispatch}
            dataCyLabel="previewTaskAdditionalActions"
          />
          <IconButton onClick={collapseSection} edge="end">
            <Icon>expand_less</Icon>
          </IconButton>
        </div>
      </div>
      <Modal
        open={renameOpen}
        cancelButtonText="Cancel"
        confirmButtonText="Save"
        onCancel={() => setRenameOpen(false)}
        onConfirm={onRename}
        disabled={!originalName}
        title="Rename Task"
      >
        <TextField
          label="Task Name *"
          value={originalName}
          name="name"
          onChange={event => setOriginalName(event.target.value)}
        />
      </Modal>
      <Modal
        open={completeModalOpen}
        loading={taskUpdateLoading}
        confirmButtonText="Complete"
        onCancel={() => setCompleteModalOpen(false)}
        onConfirm={handleCompleteToggle}
        title="Complete Task?"
      >
        Not everyone completed the task. This action will complete the task for
        everyone on the task. Are you sure?
      </Modal>
    </>
  );
};

export default TaskHeader;
