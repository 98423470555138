import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import AttributeDetails from '@atom/components/common/attributeDetail/AttributeDetails';
import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Progress } from '@atom/mui';
import { AttributesType } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  getWorkOrderAssetsEndpoint,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { getWorkAssetErrorAttributes } from '@atom/utilities/workValidationUtilities';

import ElementHeaderButton from './ElementHeaderButton';
import ElementMedia from './ElementMedia';

import './workOrderElementView.css';

const styles = {
  progress: {
    height: '100%',
    alignSelf: 'center',
  },
};

const views = [
  {
    value: 'info',
    icon: 'info',
  },
  {
    value: 'media',
    icon: 'folder',
  },
];

interface View {
  value: string;
  icon: string;
}

const hydrateElement = (element: any) => ({
  ...element,
  attributeGroups: element.attributeGroups.map(attributeGroup => ({
    ...attributeGroup,
    attributes: attributeGroup.attributes.map(attributeId => ({
      id: attributeId,
      ...element.attributes[attributeId],
    })),
  })),
});

const WorkOrderElementView = () => {
  const {
    workOrderDetail,
    workOrderAsset,
    refetchWorkOrderAssetTree,
    refetchWorkOrderAssetTreeLoading,
    activeElementId,
    previewView,
    setUnsavedChanges,
    taskId,
    refetch,
  } = useContext(WorkOrderAssetViewContext);

  const { workValidations, resolveInvalidAssetsError } = useWorkValidations();

  const [activeView, setActiveView] = useState<View>(views[0]);
  const [attributeLoading, setAttributeLoading] = useState<any[]>([]);
  // @ts-ignore
  const [element, setElement] = useState<WorkOrderAssetTreeElement>({});
  const [loading, setLoading] = useState<boolean>(false);

  const getElement = useCallback(async () => {
    setLoading(true);

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${activeElementId}/tree`;
    const { data } = await api.get(endpoint);

    setLoading(false);
    setElement(hydrateElement(data));
  }, [activeElementId, workOrderDetail.id]);

  useEffect(() => {
    // prevents duplicate calls to /tree on mount
    if (activeElementId === workOrderAsset.id && R.isEmpty(element)) {
      setElement(hydrateElement(workOrderAsset));
    } else {
      getElement();
    }
  }, [activeElementId, getElement]);

  useEffect(() => {
    if (refetchWorkOrderAssetTreeLoading && activeElementId) {
      getElement();
    }
  }, [refetchWorkOrderAssetTreeLoading]);

  const updateWorkOrderAsset = async attributeUpdateObject => {
    setAttributeLoading([attributeUpdateObject.attributeGroupName]);

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${element.id}`;
    await api.patch(endpoint, {
      attributes: attributeUpdateObject.attributes,
    });

    setAttributeLoading([]);
    getElement();
    refetchWorkOrderAssetTree();
    refetch();
  };

  const handleAttributeApproveReject = async (
    actionName: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => {
    const action = actionName === 'reject' ? 'reject' : 'approve';

    const endpoint = `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${
      element.id
    }/attributes/${attribute.id}/changes/${attribute.changeId}/${action}`;

    await api.post(endpoint);

    refetchWorkOrderAssetTree();
    refetch();
  };

  const canUpdate =
    !workOrderDetail.isClosed && hasRolePermissions(ROLE_SETS.INSPECTOR);

  const canManageChanges =
    hasRolePermissions(ROLE_SETS.MANAGER) && !previewView;

  const showProgress = loading || R.isEmpty(element);

  const errorAttributes = getWorkAssetErrorAttributes(
    element.id,
    taskId,
    workValidations,
  );

  const handleResolveInvalidAssetErrors = (
    assetId: string,
    attributeGroupId: string,
  ) => {
    resolveInvalidAssetsError(taskId, assetId, attributeGroupId);
  };

  return (
    <>
      {showProgress && (
        <div styleName="spinner-container">
          <Progress style={styles.progress} />
        </div>
      )}
      <>
        <div styleName="header-container">
          <div styleName="header-title">{loading ? '' : element.name}</div>
          <div>
            {views.map(view => {
              return (
                <ElementHeaderButton
                  key={view.value}
                  view={view}
                  activeView={activeView}
                  setActiveView={setActiveView}
                />
              );
            })}
          </div>
        </div>
        <div styleName="content-container">
          {activeView.value === 'info' ? (
            <AttributeDetails
              key={element.id}
              onPendingApproval={handleAttributeApproveReject}
              // @ts-ignore
              inventoryAssetDetail={
                isNilOrEmpty(element) ? { attributeGroups: [] } : element
              }
              onAttributeUpdate={updateWorkOrderAsset}
              loading={attributeLoading}
              canManageChanges={canManageChanges}
              canUpdate={canUpdate}
              setUnsavedChanges={setUnsavedChanges}
              errorAttributes={errorAttributes}
              resolveInvalidAssetsError={handleResolveInvalidAssetErrors}
              skipManualAttributeValidation
            />
          ) : (
            <ElementMedia
              subjectId={element.id}
              parentSubjectId={workOrderDetail.id}
              subjectType="workOrderAsset"
            />
          )}
        </div>
      </>
    </>
  );
};

export default WorkOrderElementView;
