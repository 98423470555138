import React, {
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as R from 'ramda';

import PercentBar from '@atom/components/common/percentBar/PercentBar';
import { Checkbox, Icon, ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnit } from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailComparison from '../BudgetDetailComparison';
import BudgetDetailContext from '../BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  getComparisonIcon,
  getStatusLabel,
  SELECT_COL_WIDTH,
} from '../budgetDetailUtils';

import '../budgetDetail.css';

const { TableRow, TableCell } = ListTable;

interface Props {
  budgetUnit: BudgetUnit;
}

const BudgetDetailChildUnitRow = ({ budgetUnit }: Props) => {
  const {
    budget,
    getParentUnit,
    categoryIds,
    budgetItemTemplateNames,
    budgetSummaries,
    comparisonBudgets,
    openComparisonRowId,
    setOpenComparisonRowId,
    fetchSummaries,
    loadingSummaries,
    showTracking,
    selectedUnits,
    setSelectedUnits,
  } = useContext(BudgetDetailContext);

  const [hovering, setHovering] = useState<boolean>(false);

  const open: boolean = useMemo(() => openComparisonRowId === budgetUnit.id, [
    openComparisonRowId,
  ]);

  const StatusLabel = useMemo(() => {
    return getStatusLabel(budgetUnit?.status);
  }, [budgetUnit?.status]);

  const anchorRef = useRef();

  useEffect(() => {
    if (open && !isNilOrEmpty(comparisonBudgets)) {
      fetchSummaries({
        variables: {
          input: {
            budgetUnitId: budgetUnit.id,
            budgetIds: R.pluck('id', comparisonBudgets),
            categoryIds,
            itemNames: budgetItemTemplateNames,
          },
        },
      });
    }
  }, [open, comparisonBudgets]);

  const handleNavigateToUnit = () => {
    getParentUnit({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitId: budgetUnit.id,
          categoryIds,
          budgetItemTemplateNames,
        },
      },
    });
  };

  const handleCompareClick = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenComparisonRowId(open ? null : budgetUnit.id);
  };

  return (
    <>
      <TableRow
        onClick={() => handleNavigateToUnit()}
        style={{
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {/*
          TODO: Replace with Budgeting preference
          https://atomai.atlassian.net/browse/AM-15653
        */}
        {isCurrentEnvironment([Environment.DEV]) && (
          <TableCell
            width={SELECT_COL_WIDTH}
            onClick={event => event.stopPropagation()}
          >
            <Checkbox
              checked={selectedUnits.has(budgetUnit.id)}
              onClick={() =>
                setSelectedUnits(toggleFromSet(selectedUnits, budgetUnit.id))
              }
            />
          </TableCell>
        )}
        <TableCell>
          <span styleName="budget-table-cell">
            <Icon
              color={
                budgetUnit.hasBudgetItems
                  ? colors.utility.activeBlue
                  : colors.neutral.gray
              }
            >
              folder
            </Icon>
            {budgetUnit.name}
          </span>
        </TableCell>
        {/*
          TODO: Replace with Budgeting preference
          https://atomai.atlassian.net/browse/AM-15653
        */}
        {isCurrentEnvironment([Environment.DEV]) && (
          <TableCell>
            <span styleName="budget-table-cell">{StatusLabel}</span>
          </TableCell>
        )}
        <TableCell align="right">
          <span styleName="budget-table-cell">
            {numberToLocaleString(budgetUnit?.totalBudget)}
          </span>
        </TableCell>
        {showTracking && (
          <>
            <TableCell align="right">
              <span styleName="budget-table-cell">
                {numberToLocaleString(budgetUnit?.actualBudget)}
              </span>
            </TableCell>
            <TableCell align="right">
              <span styleName="budget-table-cell">
                {numberToLocaleString(budgetUnit?.fixedCosts)}
              </span>
            </TableCell>
            <TableCell width={BUDGET_COL_WIDTH} align="right">
              <PercentBar
                value={budgetUnit?.remaining}
                limit={budgetUnit?.totalBudget}
              />
            </TableCell>
          </>
        )}
        <TableCell>
          <span
            ref={anchorRef}
            onClick={handleCompareClick}
            styleName="budget-table-cell clickable"
          >
            {getComparisonIcon(hovering, open)}
          </span>
        </TableCell>
      </TableRow>
      {open && (
        <BudgetDetailComparison
          handleCloseModal={() => setOpenComparisonRowId(null)}
          loadingSummaries={loadingSummaries}
          budgetSummaries={budgetSummaries}
          anchorEl={anchorRef?.current}
          onClickAway={() => setOpenComparisonRowId(null)}
        />
      )}
    </>
  );
};

export default BudgetDetailChildUnitRow;
