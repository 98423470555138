import React, { useContext } from 'react';

import { Select } from '@atom/mui';
import { GrantType } from '@atom/types/policy';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import { POLICY_RESOURCE_OPTIONS } from '@atom/utilities/policyUtilities';

import PolicyModalContext from './PolicyModalContext';

const { MenuItem } = Select;

const PolicyGrantTypeSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { resource, grantType, loadingExistingPolicies } = state;

  const option = POLICY_RESOURCE_OPTIONS[resource];

  /*
    TODO: Remove env toggle & filter to allow budget approval rbac grant type
    // line 26 => const menuItems = option.grantTypes;
    https://atomai.atlassian.net/browse/AM-15653
  */
  const menuItems = isCurrentEnvironment([Environment.DEV])
    ? option.grantTypes
    : option.grantTypes.filter(type => type !== GrantType.BUDGETING_APPROVAL);

  return (
    <Select
      label="Sub Source"
      value={grantType || ''}
      onChange={event =>
        updateState({ grantType: event.target.value, grants: [] })
      }
      loading={loadingExistingPolicies}
      displayEmpty
    >
      {menuItems.map(type => (
        <MenuItem value={type} key={type}>
          {POLICY_RESOURCE_OPTIONS[type]?.label || ''}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PolicyGrantTypeSelection;
